import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"_instance":"node"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  Map: { input: any; output: any; }
};

export type Query = {
  asset?: Maybe<Asset>;
  assetJobs: List;
  assets: List;
  assetType?: Maybe<AssetType>;
  assetTypeJobItems: List;
  assetTypes: List;
  assetVendors: List;
  autoAssignmentConfigs: AutoAssignmentConfigs;
  brand?: Maybe<Brand>;
  brands: List;
  chatEmployeesId?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  checklistTemplate?: Maybe<ChecklistTemplate>;
  checklistTemplateNameCollision?: Maybe<Scalars['Boolean']['output']>;
  checklistTemplates: List;
  configuration?: Maybe<UpdateConfigurationResult>;
  getDoneJobsList?: Maybe<List>;
  getFileById: File;
  getFileByUUID: File;
  getFilesList: List;
  getNotificationGroupById?: Maybe<Notification>;
  getNotificationGroups?: Maybe<List>;
  guest?: Maybe<Guest>;
  guests?: Maybe<Array<Maybe<List>>>;
  initialJobList?: Maybe<List>;
  inspectionEvaluate: AdhocJob;
  jobDefaults?: Maybe<JobDefaults>;
  jobLogs?: Maybe<Array<Maybe<JobActivityLog>>>;
  logs?: Maybe<Array<Maybe<ActivityLog>>>;
  models?: Maybe<Array<Maybe<Model>>>;
  myAddedJobs?: Maybe<MyAddedJobs>;
  myTodayJobs?: Maybe<MyTodayJobs>;
  myTodayJobsCheckIsAdded: MyTodayJobsCheckIsAdded;
  project?: Maybe<Project>;
  projectAdhocJobsTotalCount?: Maybe<Scalars['Int']['output']>;
  projectCycleJobs?: Maybe<List>;
  projectCycleQueuedJobDetailView?: Maybe<Job>;
  projectCycles?: Maybe<List>;
  projectNameExists?: Maybe<ProjectNameExists>;
  projects: List;
  projectSummaries?: Maybe<List>;
  repeatingJob: RepeatingJob;
  repeatingJobOccurrenceSummary: RepeatingJobOccurrenceSummary;
  repeatingJobSummaries: List;
  reports: List;
  reportsGenerateEncryptedEmbedUrl: Scalars['String']['output'];
  reservation?: Maybe<Reservation>;
  reservations?: Maybe<List>;
  roomReservations?: Maybe<List>;
  rooms: Array<RoomLocation>;
  roomsById?: Maybe<Array<Maybe<RoomSummary>>>;
  roomSummary?: Maybe<RoomSummary>;
  teamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  timelineFilters: TimelineFilterResults;
  timelineJobs: Timeline;
  todayJobs?: Maybe<List>;
  totalUnassignedJobs: Scalars['Int']['output'];
  unassignedJobs?: Maybe<Array<Maybe<UnassignedJob>>>;
};


export type QueryAssetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAssetJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssetTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAssetTypeJobItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssetTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssetVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChecklistTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChecklistTemplateNameCollisionArgs = {
  name: Scalars['String']['input'];
};


export type QueryChecklistTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDoneJobsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFileByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetFileByUuidArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetNotificationGroupByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetNotificationGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGuestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuestsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  Page?: InputMaybe<Scalars['Int']['input']>;
  PageSize?: InputMaybe<Scalars['Int']['input']>;
  Sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInitialJobListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['Map']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInspectionEvaluateArgs = {
  jobID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobDefaultsArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  locationID?: InputMaybe<Scalars['Int']['input']>;
  roomTypeID?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryJobLogsArgs = {
  aggregateId: Scalars['ID']['input'];
};


export type QueryLogsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMyAddedJobsArgs = {
  myAddedJobsInput?: InputMaybe<MyAddedJobsInput>;
};


export type QueryMyTodayJobsArgs = {
  myTodayJobsInput?: InputMaybe<MyTodayJobsInput>;
};


export type QueryMyTodayJobsCheckIsAddedArgs = {
  filters?: InputMaybe<MyTodayJobsCheckIsAddedFilters>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectAdhocJobsTotalCountArgs = {
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProjectCycleJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectCycleId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectCycleQueuedJobDetailViewArgs = {
  jobCardHash?: InputMaybe<Scalars['String']['input']>;
  projectCycleId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProjectCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectNameExistsArgs = {
  name: Scalars['String']['input'];
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectSummariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRepeatingJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRepeatingJobOccurrenceSummaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRepeatingJobSummariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReportsGenerateEncryptedEmbedUrlArgs = {
  embedKey?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReservationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReservationsArgs = {
  after?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomReservationsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomsByIdArgs = {
  id: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryRoomSummaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTeamMembersArgs = {
  filters?: InputMaybe<TeamMemberFilters>;
};


export type QueryTimelineFiltersArgs = {
  filters?: InputMaybe<TimelineJobFilters>;
};


export type QueryTimelineJobsArgs = {
  filters?: InputMaybe<TimelineJobFilters>;
};


export type QueryTodayJobsArgs = {
  locationID: Scalars['Int']['input'];
  UTC: Scalars['Int']['input'];
};


export type QueryUnassignedJobsArgs = {
  filters?: InputMaybe<UnassignedJobFilters>;
};

export type Asset = {
  assetType?: Maybe<AssetType>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  id?: Maybe<Scalars['ID']['output']>;
  installationDate?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  lastModifiedByUserId?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  location: Location;
  notes?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<AssetPurchase>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Status>;
  type?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<AssetVendor>;
  version: Scalars['Int']['output'];
  warranty?: Maybe<AssetWarranty>;
};

export type AssetType = {
  assetCount?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<BrandInfo>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Document>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  jobItem: JobItem;
  lastModifiedByUserId?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  lifeCycle?: Maybe<LifeCycle>;
  modelName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  serviceCycle?: Maybe<AssetServiceCycle>;
  status?: Maybe<Status>;
  version: Scalars['Int']['output'];
};

export type BrandInfo = {
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type Document = {
  uuid: Scalars['String']['output'];
};

export type JobItem = {
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
};

export type LifeCycle = {
  lifeCyclePeriodType?: Maybe<LifeCycleType>;
  number?: Maybe<Scalars['Int']['output']>;
};

export enum LifeCycleType {
  Month = 'month',
  Year = 'year'
}

export type AssetServiceCycle = {
  displayName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssetTypeServiceCycleType>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum AssetTypeServiceCycleType {
  Cron = 'cron',
  Days = 'days',
  MonthlyOnDayOfWeek = 'monthlyOnDayOfWeek',
  Months = 'months',
  NoRepeat = 'noRepeat',
  Unknown = 'unknown',
  WeeklyOnDay = 'weeklyOnDay',
  Weeks = 'weeks',
  Years = 'years'
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type Location = {
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  longDisplayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AssetPurchase = {
  date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
};

export type AssetVendor = {
  address?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUsedInMultipleAssets?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type AssetWarranty = {
  description?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  warrantyPeriodType?: Maybe<WarrantyPeriodType>;
};

export enum WarrantyPeriodType {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}

export type List = {
  edges?: Maybe<Array<Edge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: ListNode;
};

export type ListNode = AdhocJob | Asset | AssetJob | AssetType | AssetVendor | Brand | ChecklistTemplate | DoneJobListItem | File | Guest | InitialJobListItem | Job | JobItem | Notification | Project | ProjectCycle | ProjectCycleJob | ProjectSummary | RepeatingJobSummary | Report | Reservation | RoomSummary;

export type AdhocJob = {
  action?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignedAt?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<AdhocJobEmployee>;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  checklist_templates?: Maybe<Array<Maybe<ChecklistTemplate>>>;
  checklists?: Maybe<Array<Maybe<ChecklistTemplate>>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Array<AdhocJobDept>>;
  id: Scalars['Int']['output'];
  isEscalated?: Maybe<Scalars['Boolean']['output']>;
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<AdhocJobItems>>>;
  location?: Maybe<Array<AdhocJobLocation>>;
  metadata?: Maybe<AdhocJobMetadata>;
  notes?: Maybe<Array<Maybe<AdhocJobNotes>>>;
  parent_checklist_task?: Maybe<ChecklistTemplateTask>;
  parent_checklist_task_id?: Maybe<Scalars['String']['output']>;
  periods?: Maybe<Array<Maybe<AdhocJobPeriod>>>;
  priority: Scalars['String']['output'];
  reporter?: Maybe<AdhocJobEmployee>;
  roles?: Maybe<Array<Maybe<AdhocJobRole>>>;
  self?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  targetTime?: Maybe<Scalars['String']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalDurationSecs?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobEmployee = {
  employeeId: Scalars['Int']['output'];
  employeeSelf?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplate = {
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creator: ChecklistCreator;
  creatorId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  Order?: Maybe<Scalars['Int']['output']>;
  tasks?: Maybe<Array<ChecklistTemplateTask>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistCreator = {
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTemplateTask = {
  attachment?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklistTemplateId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data_temperature_scale?: Maybe<Scalars['String']['output']>;
  dataCheckboxChecked?: Maybe<Scalars['Boolean']['output']>;
  dataDropdownValue?: Maybe<Array<Scalars['String']['output']>>;
  dataNumerical?: Maybe<Scalars['Float']['output']>;
  dataOnOff?: Maybe<Scalars['Boolean']['output']>;
  dataPassFail?: Maybe<EnumPassFail>;
  dataTemperatureScale?: Maybe<EnumTemperatureScale>;
  dataTemperatureValue?: Maybe<Scalars['Float']['output']>;
  dataText?: Maybe<Scalars['String']['output']>;
  dataYesNo?: Maybe<EnumYesNo>;
  defaultValue?: Maybe<Scalars['JSON']['output']>;
  dropdownOptions?: Maybe<Array<Scalars['String']['output']>>;
  fulfillmentAttachment?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fulfillmentNote?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  ordering_value?: Maybe<Scalars['String']['output']>;
  orderingValue?: Maybe<Scalars['String']['output']>;
  parent_task_id?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  taskType?: Maybe<Scalars['String']['output']>;
  templateTaskId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum EnumPassFail {
  Error = 'ERROR',
  NotApplicable = 'NOT_APPLICABLE',
  Passed = 'PASSED'
}

export enum EnumTemperatureScale {
  Celsius = 'celsius',
  Fahrenheit = 'fahrenheit',
  Kelvin = 'kelvin'
}

export enum EnumYesNo {
  No = 'NO',
  NotApplicable = 'NOT_APPLICABLE',
  Yes = 'YES'
}

export type AdhocJobDept = {
  departmentCode?: Maybe<Scalars['String']['output']>;
  departmentId?: Maybe<Scalars['Int']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  departmentSelf?: Maybe<Scalars['String']['output']>;
  Id: Scalars['Int']['output'];
};

export type AdhocJobItems = {
  amount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobLocation = {
  id: Scalars['Int']['output'];
  locationId?: Maybe<Scalars['Int']['output']>;
  locationSelf?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobMetadata = {
  externalId?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  privateAttributes?: Maybe<Scalars['JSON']['output']>;
  publicAttributes?: Maybe<Scalars['JSON']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobNotes = {
  comentor?: Maybe<AdhocJobEmployee>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobPeriod = {
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<AdhocJobEmployee>;
  assigneeId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  employeeSalary?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  periodCost?: Maybe<Scalars['Float']['output']>;
  periodDurationSecs?: Maybe<Scalars['Int']['output']>;
  periodEnd?: Maybe<Scalars['String']['output']>;
  periodStart?: Maybe<Scalars['String']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobRole = {
  id?: Maybe<Scalars['Int']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  roleSelf?: Maybe<Scalars['String']['output']>;
};

export type AssetJob = {
  action?: Maybe<JobAction>;
  assetId?: Maybe<Scalars['Int']['output']>;
  assignee?: Maybe<Assignee>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  departments?: Maybe<Array<Maybe<Department>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  priority?: Maybe<JobPriority>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<JobStatus>;
};

export enum JobAction {
  Check = 'check',
  Clean = 'clean',
  Correction = 'correction',
  Deliver = 'deliver',
  Departure = 'departure',
  Find = 'find',
  Fix = 'fix',
  Inspect = 'inspect',
  Inspection = 'inspection',
  Install = 'install',
  NoAction = 'noAction',
  Perform = 'perform',
  Remove = 'remove',
  Repair = 'repair',
  Replace = 'replace',
  Stayover = 'stayover',
  Test = 'test',
  Touchup = 'touchup',
  Turndown = 'turndown'
}

export type Assignee = {
  displayName?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<Employee>;
};

export type Employee = {
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Department = {
  departmentCode?: Maybe<Scalars['String']['output']>;
  departmentId?: Maybe<Scalars['ID']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Note = {
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export enum JobPriority {
  High = 'high',
  Highest = 'highest',
  Low = 'low',
  Medium = 'medium'
}

export enum JobStatus {
  Archived = 'archived',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Deleted = 'deleted',
  Done = 'done',
  InProgress = 'in_progress',
  New = 'new',
  NotStarted = 'not_started',
  OnHold = 'on_hold',
  Pending = 'pending',
  Queued = 'queued',
  Unassigned = 'unassigned'
}

export type Brand = {
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedByUserId?: Maybe<Scalars['ID']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type DoneJobListItem = {
  action?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<UserMin>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklists?: Maybe<Array<Checklist>>;
  checklistTemplate?: Maybe<Array<ChecklistTemplate>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<DepartmentMin>;
  doBy?: Maybe<Scalars['Int']['output']>;
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEscalated: Scalars['Boolean']['output'];
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item: Scalars['String']['output'];
  items?: Maybe<Array<JobItemInfo>>;
  location?: Maybe<Array<Maybe<LocationMin>>>;
  locations?: Maybe<Array<Maybe<LocationMin>>>;
  metadata?: Maybe<JobMetadata>;
  notes?: Maybe<Array<Maybe<JobNote>>>;
  parentChecklistTask?: Maybe<ChecklistTask>;
  parentChecklistTaskId?: Maybe<Scalars['String']['output']>;
  periods: Array<Maybe<JobPeriod>>;
  predictiveDueTime?: Maybe<Scalars['Int']['output']>;
  priority: Scalars['String']['output'];
  reporter?: Maybe<UserMin>;
  role?: Maybe<RoleMin>;
  room?: Maybe<RoomSummary>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomsData?: Maybe<Array<Maybe<RoomSummary>>>;
  scheduleStartTimeAt?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  timeWindowEnd?: Maybe<Scalars['Int']['output']>;
  timeWindowStart?: Maybe<Scalars['Int']['output']>;
  type: JobType;
};

export type UserMin = {
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type Checklist = {
  checklistTasks?: Maybe<Array<Maybe<ChecklistTask>>>;
  checklistTemplate?: Maybe<ChecklistTemplate>;
  checklistTemplateId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasUncompletedChecklistTask?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  jobId: Scalars['Int']['output'];
  Order: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChecklistTask = {
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklistId?: Maybe<Scalars['String']['output']>;
  childJobsIds?: Maybe<Array<Scalars['Int']['output']>>;
  createdAt: Scalars['String']['output'];
  dataCheckboxChecked?: Maybe<Scalars['Boolean']['output']>;
  dataDropdownValue?: Maybe<Array<Scalars['String']['output']>>;
  dataNumerical?: Maybe<Scalars['Float']['output']>;
  dataOnOff?: Maybe<Scalars['Boolean']['output']>;
  dataPassError?: Maybe<Scalars['String']['output']>;
  dataTemperatureScale?: Maybe<Scalars['String']['output']>;
  dataTemperatureValue?: Maybe<Scalars['Float']['output']>;
  dataText?: Maybe<Scalars['String']['output']>;
  dataYesNo?: Maybe<Scalars['String']['output']>;
  dropdownOptions?: Maybe<Array<Scalars['String']['output']>>;
  fulfillmentAttachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fulfillmentNote?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  orderingValue: Scalars['String']['output'];
  parentTaksId?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
  taskType: Scalars['String']['output'];
  templateTaskId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DepartmentMin = {
  departmentCode?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type JobItemInfo = {
  amount?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LocationMin = {
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  longDisplayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type JobMetadata = {
  externalId?: Maybe<Scalars['String']['output']>;
  privateAttributes?: Maybe<Scalars['Map']['output']>;
  publicAttributes?: Maybe<Scalars['Map']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type JobNote = {
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type User = {
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type JobPeriod = {
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['ID']['output']>;
  jobId?: Maybe<Scalars['ID']['output']>;
  periodEnd?: Maybe<Scalars['Int']['output']>;
  periodStart?: Maybe<Scalars['Int']['output']>;
  periodType?: Maybe<Scalars['String']['output']>;
};

export type RoleMin = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoomSummary = {
  cleaningExceptionStatus?: Maybe<CleaningExceptionStatus>;
  cleaningStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId: Scalars['Int']['output'];
  locationType?: Maybe<LocationType>;
  longDisplayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occupancyStatus?: Maybe<Scalars['String']['output']>;
  outOfOrderDates?: Maybe<Array<Maybe<OutOfOrderDate>>>;
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  roomType?: Maybe<RoomType>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
};

export type CleaningExceptionStatus = {
  doNotDisturb?: Maybe<Scalars['Boolean']['output']>;
  DoNotDisturb?: Maybe<Scalars['Boolean']['output']>;
  doubleLockActive?: Maybe<Scalars['Boolean']['output']>;
  DoubleLockActive?: Maybe<Scalars['Boolean']['output']>;
  noServiceRequired?: Maybe<Scalars['Boolean']['output']>;
  NoServiceRequired?: Maybe<Scalars['Boolean']['output']>;
  sleepOutNoShow?: Maybe<Scalars['Boolean']['output']>;
  SleepOutNoShow?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationType = {
  code: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  squareUnit?: Maybe<Scalars['Int']['output']>;
};

export type OutOfOrderDate = {
  code?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type Reservation = {
  arrivalDate: Scalars['String']['output'];
  attributes?: Maybe<Scalars['JSON']['output']>;
  departureDate: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  estimatedArrivalTime?: Maybe<Scalars['String']['output']>;
  estimatedDepartureTime?: Maybe<Scalars['String']['output']>;
  externalGroupReservationId?: Maybe<Scalars['ID']['output']>;
  externalReservationId: Scalars['ID']['output'];
  guestNumber?: Maybe<GuestNumber>;
  guests?: Maybe<Array<Maybe<ReservationGuest>>>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  noOfAdults: Scalars['Int']['output'];
  noOfChildren: Scalars['Int']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  preferences?: Maybe<Array<Maybe<Preference>>>;
  reservationStatus: ReservationStatus;
  specialRequestCodes?: Maybe<Scalars['String']['output']>;
  statusLabel?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type GuestNumber = {
  adults?: Maybe<Scalars['Int']['output']>;
  children?: Maybe<Scalars['Int']['output']>;
};

export type ReservationGuest = {
  country?: Maybe<Scalars['String']['output']>;
  externalGuestProfileId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Array<Maybe<Preference>>>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Preference = {
  description?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ReservationStatus {
  Cancelled = 'Cancelled',
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  Reserved = 'Reserved',
  Unknown = 'Unknown'
}

export type RoomType = {
  code?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export enum JobType {
  Guest = 'guest',
  Housekeeping = 'housekeeping',
  Internal = 'internal'
}

export type File = {
  createdAt: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  URL: Scalars['String']['output'];
  UUID: Scalars['String']['output'];
};

export type Guest = {
  applicationId?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdByUserId?: Maybe<Scalars['Int']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  externalGuestProfileId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastModifiedByUserId?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Array<Maybe<Preference>>>;
  version: Scalars['Int']['output'];
};

export type InitialJobListItem = {
  action?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<UserMin>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklists?: Maybe<Array<Checklist>>;
  checklistTemplate?: Maybe<Array<ChecklistTemplate>>;
  completedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<DepartmentMin>;
  doBy?: Maybe<Scalars['Int']['output']>;
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEscalated: Scalars['Boolean']['output'];
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item: Scalars['String']['output'];
  items?: Maybe<Array<JobItemInfo>>;
  location?: Maybe<Array<Maybe<LocationMin>>>;
  locations?: Maybe<Array<Maybe<LocationMin>>>;
  metadata?: Maybe<JobMetadata>;
  notes?: Maybe<Array<Maybe<JobNote>>>;
  parentChecklistTask?: Maybe<ChecklistTask>;
  parentChecklistTaskId?: Maybe<Scalars['String']['output']>;
  periods: Array<Maybe<JobPeriod>>;
  predictiveDueTime?: Maybe<Scalars['Int']['output']>;
  priority: Scalars['String']['output'];
  reporter?: Maybe<UserMin>;
  role?: Maybe<RoleMin>;
  room?: Maybe<RoomSummary>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomsData?: Maybe<Array<Maybe<RoomSummary>>>;
  scheduleStartTimeAt?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  timeWindowEnd?: Maybe<Scalars['Int']['output']>;
  timeWindowStart?: Maybe<Scalars['Int']['output']>;
  type: JobType;
};

export type Job = {
  action?: Maybe<JobAction>;
  assignee?: Maybe<User>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklist_templates?: Maybe<Array<Maybe<ChecklistTemplate>>>;
  checklists?: Maybe<Array<Maybe<JobChecklists>>>;
  completedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  department?: Maybe<Department>;
  doBy?: Maybe<Scalars['Int']['output']>;
  dueByTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isEscalated?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<NeoJobItem>>>;
  locations: Array<Maybe<Location>>;
  metadata?: Maybe<Metadata>;
  notes?: Maybe<Array<Maybe<JobNote>>>;
  periods: Array<Maybe<JobPeriod>>;
  priority: JobPriority;
  propertyId?: Maybe<Scalars['String']['output']>;
  reporter?: Maybe<User>;
  role?: Maybe<Role>;
  scheduleStartTimeAt?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Int']['output']>;
  status: JobStatus;
  type: JobType;
};

export type JobChecklists = {
  checklistTemplate?: Maybe<ChecklistTemplate>;
  checklistTemplateId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
};

export type NeoJobItem = {
  amount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Metadata = {
  changes?: Maybe<Array<Maybe<Changes>>>;
  externalId?: Maybe<Scalars['String']['output']>;
  guest?: Maybe<Guest>;
  jobId?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  privateAttributes?: Maybe<PrivateAttributes>;
  publicAttributes?: Maybe<PublicAttributes>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  source?: Maybe<Scalars['String']['output']>;
};

export type Changes = {
  key?: Maybe<Scalars['JSON']['output']>;
  new?: Maybe<Scalars['JSON']['output']>;
  old?: Maybe<Scalars['JSON']['output']>;
};

export type PrivateAttributes = {
  projectCycleSvc?: Maybe<ProjectServiceCycleAttribute>;
};

export type ProjectServiceCycleAttribute = {
  jobCardId?: Maybe<Scalars['ID']['output']>;
  projectCycleId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  schemaVersion?: Maybe<Scalars['String']['output']>;
};

export type PublicAttributes = {
  asset?: Maybe<NestedId>;
};

export type NestedId = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type Role = {
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  roleId?: Maybe<Scalars['ID']['output']>;
};

export type Notification = {
  audit?: Maybe<Audit>;
  displayName: Scalars['String']['output'];
  eventActionRules: Array<Maybe<EventActionRule>>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  userEventActionRules?: Maybe<Array<Maybe<UserEventActionRule>>>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Audit = {
  created?: Maybe<AuditCreated>;
  lastModified?: Maybe<AuditLastModified>;
};

export type AuditCreated = {
  timestamp?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type AuditLastModified = {
  timestamp?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type EventActionRule = {
  actions?: Maybe<Array<Maybe<Actions>>>;
  audit?: Maybe<Audit>;
  displayName?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  eventActionRuleGroupId?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  shouldProcessExistingEntitiesOnCreation?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type Actions = {
  body?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  recipients?: Maybe<Recipients>;
  templateName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ActionType>;
};

export type Recipients = {
  roles?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export enum ActionType {
  EscalateJob = 'EscalateJob',
  SendEmail = 'SendEmail',
  SendNotification = 'SendNotification'
}

export type Event = {
  conditions?: Maybe<Array<Maybe<Condition>>>;
  context?: Maybe<EventContext>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  id?: Maybe<Scalars['ID']['output']>;
  schedule?: Maybe<Schedule>;
  type?: Maybe<Array<Maybe<EventType>>>;
};

export type Condition = {
  id?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ConditionType>;
};

export enum ConditionType {
  PropertyValueChanged = 'PropertyValueChanged'
}

export enum EventContext {
  ChecklistTask = 'ChecklistTask',
  Job = 'Job',
  NumericalTask = 'NumericalTask',
  ProjectCycle = 'ProjectCycle',
  TemperatureTask = 'TemperatureTask'
}

export type Filter = {
  id?: Maybe<Scalars['ID']['output']>;
  operator: Operator;
  property: Scalars['String']['output'];
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum Operator {
  In = 'In',
  NotIn = 'NotIn',
  NotNull = 'NotNull',
  Null = 'Null',
  OutsideRange = 'OutsideRange'
}

export type Schedule = {
  context: ScheduleContext;
  entityPropertyName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  offsetDuration?: Maybe<Scalars['String']['output']>;
  offsetType?: Maybe<OffsetType>;
};

export enum ScheduleContext {
  EntityProperty = 'EntityProperty',
  Event = 'Event'
}

export enum OffsetType {
  After = 'After',
  Before = 'Before',
  On = 'On'
}

export enum EventType {
  EntityCreated = 'EntityCreated',
  EntityDeleted = 'EntityDeleted',
  EntityModified = 'EntityModified'
}

export type UserEventActionRule = {
  actions?: Maybe<Array<Maybe<UserActions>>>;
  event?: Maybe<UserEvent>;
};

export type UserActions = {
  ids?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  legacyAssigneeFilter?: Maybe<LegacyAssigneeFilter>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  schedule?: Maybe<UserSchedule>;
  type?: Maybe<Array<Maybe<ActionType>>>;
};

export type LegacyAssigneeFilter = {
  eventId?: Maybe<Scalars['ID']['output']>;
  values?: Maybe<Scalars['String']['output']>;
};

export type UserSchedule = {
  offsetDuration?: Maybe<Scalars['String']['output']>;
  offsetType?: Maybe<UserOffsetType>;
};

export enum UserOffsetType {
  After = 'After',
  Before = 'Before',
  Created = 'Created',
  Status = 'Status'
}

export type UserEvent = {
  context?: Maybe<EventContext>;
  filters?: Maybe<Array<Maybe<UserFilter>>>;
};

export type UserFilter = {
  Action?: Maybe<Filter>;
  Assignee?: Maybe<Filter>;
  DataTask?: Maybe<Filter>;
  DataTaskNull?: Maybe<Filter>;
  Department?: Maybe<Filter>;
  Priority?: Maybe<Filter>;
  ProjectId?: Maybe<Filter>;
  Roles?: Maybe<Filter>;
  Status?: Maybe<Filter>;
  TaskType?: Maybe<Filter>;
  TemplateTaskId?: Maybe<Filter>;
  Type?: Maybe<Filter>;
};

export type Project = {
  audit?: Maybe<Audit>;
  currentCycle?: Maybe<Cycle>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nextCycle?: Maybe<Cycle>;
  projectCycles?: Maybe<Array<Maybe<ProjectCycle>>>;
  projectEndDate?: Maybe<Scalars['String']['output']>;
  projectJobDetails?: Maybe<Array<Maybe<ProjectJobDetails>>>;
  projectStartDate: Scalars['String']['output'];
  serviceCycle: ServiceCycle;
  serviceCycleId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Status>;
  version: Scalars['Int']['output'];
};

export type Cycle = {
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  startDate: Scalars['String']['output'];
};

export type ProjectCycle = {
  audit?: Maybe<Audit>;
  displayEndDate?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayStartDate: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  frequencyHash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  percentComplete: Scalars['Int']['output'];
  percentTimeComplete: Scalars['Int']['output'];
  projectCycleHash?: Maybe<Scalars['String']['output']>;
  projectCycleStatus?: Maybe<ProjectCycleStatus>;
  startDate: Scalars['String']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export enum ProjectCycleStatus {
  Deleted = 'deleted',
  Ended = 'ended',
  InProgress = 'inProgress',
  Planned = 'planned'
}

export type ProjectJobDetails = {
  displayName?: Maybe<Scalars['String']['output']>;
  jobAssign?: Maybe<JobAssign>;
  jobCancellation?: Maybe<JobCancellation>;
  jobRules?: Maybe<Array<Maybe<JobRules>>>;
  jobTemplate?: Maybe<JobTemplate>;
};

export type JobAssign = {
  assignableEmployeeIds?: Maybe<Array<Scalars['Int']['output']>>;
  autoAssignType: AutoAssignType;
};

export enum AutoAssignType {
  None = 'None',
  Schedule = 'Schedule',
  SpecificEmployees = 'SpecificEmployees'
}

export type JobCancellation = {
  autoCancelJobs: Scalars['Boolean']['output'];
  cancellationStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type JobRules = {
  context?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<JobRuleOperator>;
  property?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum JobRuleOperator {
  Is = 'is',
  IsNot = 'isNot'
}

export type JobTemplate = {
  action?: Maybe<JobAction>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklistTemplates?: Maybe<Array<Maybe<ProjectJobChecklist>>>;
  departments?: Maybe<Array<Maybe<Department>>>;
  dueByTime?: Maybe<Scalars['String']['output']>;
  jobItem?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  priority: JobPriority;
  roles?: Maybe<Array<Maybe<Role>>>;
  scheduleStartTimeAt?: Maybe<Scalars['String']['output']>;
};

export type ProjectJobChecklist = {
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceCycle = {
  displayName?: Maybe<Scalars['String']['output']>;
  type: ServiceCycleType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ServiceCycleType {
  Cron = 'cron',
  Days = 'days',
  MonthlyOnDayOfWeek = 'monthlyOnDayOfWeek',
  Months = 'months',
  NoRepeat = 'noRepeat',
  WeeklyOnDay = 'weeklyOnDay',
  Weeks = 'weeks',
  Years = 'years'
}

export type ProjectCycleJob = {
  asset?: Maybe<ProjectAsset>;
  assignee?: Maybe<Assignee>;
  departments?: Maybe<Array<Maybe<Department>>>;
  id: Scalars['ID']['output'];
  job?: Maybe<NestedId>;
  jobCardHash: Scalars['String']['output'];
  jobItem?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<Maybe<ProjectCycleJobLocation>>>;
  status?: Maybe<JobStatus>;
};

export type ProjectAsset = {
  assetType?: Maybe<AssetType>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type ProjectCycleJobLocation = {
  cleaningStatus?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['ID']['output'];
  longDisplayName?: Maybe<Scalars['String']['output']>;
  occupancyStatus?: Maybe<Scalars['String']['output']>;
  outOfOrderDates?: Maybe<Array<Maybe<OutOfOrderDate>>>;
  shortDisplayName?: Maybe<Scalars['String']['output']>;
};

export type ProjectSummary = {
  currentCycle?: Maybe<Cycle>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nextCycle?: Maybe<Cycle>;
  percentComplete: Scalars['Int']['output'];
  percentTimeComplete: Scalars['Int']['output'];
  serviceCycle?: Maybe<ServiceCycle>;
  status?: Maybe<Status>;
};

export type RepeatingJobSummary = {
  department?: Maybe<Array<Maybe<Department>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Array<Maybe<SummaryLocation>>>;
  roles?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type SummaryLocation = {
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type Report = {
  description?: Maybe<Scalars['String']['output']>;
  embedKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PageInfo = {
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type AutoAssignmentConfigs = {
  check: Array<Scalars['String']['output']>;
  clean: Array<Scalars['String']['output']>;
  correction: Array<Scalars['String']['output']>;
  departure: Array<Scalars['String']['output']>;
  exists: AutoAssignmentConfigsExists;
  inspection: Array<Scalars['String']['output']>;
  stayover: Array<Scalars['String']['output']>;
  touchup: Array<Scalars['String']['output']>;
  turndown: Array<Scalars['String']['output']>;
};

export type AutoAssignmentConfigsExists = {
  check: Scalars['Boolean']['output'];
  clean: Scalars['Boolean']['output'];
  correction: Scalars['Boolean']['output'];
  departure: Scalars['Boolean']['output'];
  inspection: Scalars['Boolean']['output'];
  stayover: Scalars['Boolean']['output'];
  touchup: Scalars['Boolean']['output'];
  turndown: Scalars['Boolean']['output'];
};

export type UpdateConfigurationResult = {
  isEnabled: Scalars['Boolean']['output'];
  triggerTime: Scalars['String']['output'];
};

export type JobDefaults = {
  checklistsTemplate?: Maybe<Array<Maybe<ChecklistTemplate>>>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<Department>;
  durationMin?: Maybe<Scalars['Float']['output']>;
  role?: Maybe<Role>;
};

export type JobActivityLog = {
  action: Scalars['String']['output'];
  aggregateId: Scalars['ID']['output'];
  aggregateType: Scalars['String']['output'];
  aggregateVersion: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Metadata>;
  recordedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ActivityLog = {
  action: Scalars['String']['output'];
  aggregateId: Scalars['ID']['output'];
  aggregateType: Scalars['String']['output'];
  aggregateVersion: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  metadata?: Maybe<Metadata>;
  recordedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type Model = {
  modelName: Scalars['String']['output'];
};

export type MyAddedJobsInput = {
  employeeId: Scalars['ID']['input'];
};

export type MyAddedJobs = {
  jobs?: Maybe<Array<MyAddedJob>>;
};

export type MyAddedJob = {
  action?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['String']['output']>;
  assignedAt?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<UserMin>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklists?: Maybe<Array<Checklist>>;
  checklistTemplate?: Maybe<Array<ChecklistTemplate>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<Department>;
  doBy?: Maybe<Scalars['String']['output']>;
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEscalated: Scalars['Boolean']['output'];
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item: Scalars['String']['output'];
  items?: Maybe<Array<JobItemInfo>>;
  location?: Maybe<Array<Maybe<LocationMin>>>;
  locations?: Maybe<Array<Maybe<LocationMin>>>;
  metadata?: Maybe<JobMetadata>;
  notes?: Maybe<Array<Maybe<MyJobsNote>>>;
  parentChecklistTask?: Maybe<ChecklistTask>;
  parentChecklistTaskId?: Maybe<Scalars['String']['output']>;
  periods: Array<Maybe<JobPeriod>>;
  predictiveDueTime?: Maybe<Scalars['String']['output']>;
  priority: Scalars['String']['output'];
  reporter?: Maybe<UserMin>;
  role?: Maybe<RoleMin>;
  room?: Maybe<RoomSummary>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomsData?: Maybe<Array<Maybe<RoomSummary>>>;
  scheduleStartTimeAt?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  targetTime?: Maybe<Scalars['String']['output']>;
  timeWindowEnd?: Maybe<Scalars['Int']['output']>;
  timeWindowStart?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['Int']['output']>;
  totalDurationSecs?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type MyJobsNote = {
  commentor?: Maybe<AdhocJobNoteCommentor>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdhocJobNoteCommentor = {
  employeeId?: Maybe<Scalars['Int']['output']>;
  employeeSelf?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type MyTodayJobsInput = {
  date: Scalars['String']['input'];
  employeeId: Scalars['ID']['input'];
};

export type MyTodayJobs = {
  checksum: Scalars['Int']['output'];
  jobs: Array<MyTodayJob>;
};

export type MyTodayJob = {
  action?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['String']['output']>;
  assignedAt?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<UserMin>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checklists?: Maybe<Array<Checklist>>;
  checklistTemplate?: Maybe<Array<ChecklistTemplate>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creditValue?: Maybe<Scalars['Float']['output']>;
  department?: Maybe<Department>;
  doBy?: Maybe<Scalars['String']['output']>;
  durationMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEscalated: Scalars['Boolean']['output'];
  isRushed?: Maybe<Scalars['Boolean']['output']>;
  item: Scalars['String']['output'];
  items?: Maybe<Array<JobItemInfo>>;
  location?: Maybe<Array<Maybe<LocationMin>>>;
  locations?: Maybe<Array<Maybe<LocationMin>>>;
  metadata?: Maybe<JobMetadata>;
  notes?: Maybe<Array<Maybe<MyJobsNote>>>;
  parentChecklistTask?: Maybe<ChecklistTask>;
  parentChecklistTaskId?: Maybe<Scalars['String']['output']>;
  periods: Array<Maybe<JobPeriod>>;
  predictiveDueTime?: Maybe<Scalars['String']['output']>;
  priority: Scalars['String']['output'];
  reporter?: Maybe<UserMin>;
  role?: Maybe<RoleMin>;
  room?: Maybe<RoomSummary>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomsData?: Maybe<Array<Maybe<RoomSummary>>>;
  scheduleStartTimeAt?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  targetTime?: Maybe<Scalars['String']['output']>;
  timeWindowEnd?: Maybe<Scalars['Int']['output']>;
  timeWindowStart?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['Int']['output']>;
  totalDurationSecs?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type MyTodayJobsCheckIsAddedFilters = {
  date: Scalars['String']['input'];
  employeeId: Scalars['ID']['input'];
};

export type MyTodayJobsCheckIsAdded = {
  isAdded?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectNameExists = {
  nameExists?: Maybe<Scalars['Boolean']['output']>;
};

export type RepeatingJob = {
  audit?: Maybe<Audit>;
  cycle?: Maybe<RepeatingJobCycle>;
  displayName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  frequencyHash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  jobTemplate?: Maybe<Job>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: Status;
  version?: Maybe<Scalars['Int']['output']>;
};

export type RepeatingJobCycle = {
  displayName: Scalars['String']['output'];
  frequency?: Maybe<RepeatCycleCadence>;
  type: CycleType;
  value: Scalars['String']['output'];
};

export enum RepeatCycleCadence {
  Daily = 'daily',
  Monthly = 'monthly',
  Unknown = 'unknown',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export enum CycleType {
  Cron = 'cron',
  Rrule = 'rrule'
}

export type RepeatingJobOccurrenceSummary = {
  lastOccurrenceDate?: Maybe<Scalars['String']['output']>;
  nextOccurrenceDate?: Maybe<Scalars['String']['output']>;
  repeatingJobId: Scalars['ID']['output'];
};

export type RoomLocation = {
  cleaningStatus?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  occupancyStatus?: Maybe<Scalars['String']['output']>;
  outOfOrderDates?: Maybe<Array<Maybe<OutOfOrderDate>>>;
  roomType?: Maybe<RoomType>;
};

export type TeamMemberFilters = {
  currentDate: Scalars['String']['input'];
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listType: TeamMemberListType;
};

export enum TeamMemberListType {
  All = 'ALL',
  NotOnTimeline = 'NOT_ON_TIMELINE',
  OnTimeline = 'ON_TIMELINE'
}

export type TeamMember = {
  children?: Maybe<Array<Maybe<TeamMember>>>;
  createdAt: Scalars['String']['output'];
  creditQuota?: Maybe<Scalars['Float']['output']>;
  creditsAssigned?: Maybe<Scalars['Float']['output']>;
  employeeId: Scalars['Int']['output'];
  employeeSelf: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  hasRoleDefaults?: Maybe<Scalars['Boolean']['output']>;
  hasUserDefaults?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  jobIds?: Maybe<Array<Scalars['Int']['output']>>;
  locationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  onSchedule?: Maybe<Scalars['Boolean']['output']>;
  onTimeline?: Maybe<Scalars['Boolean']['output']>;
  propertyDetails?: Maybe<Array<TeamMemberPropertyDetail>>;
  startTime?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stopTime?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  teamMemberId?: Maybe<Scalars['Int']['output']>;
  teamMemberParentId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userFirstName: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
  userLastName: Scalars['String']['output'];
  userName: Scalars['String']['output'];
  userSelf: Scalars['String']['output'];
};

export type TeamMemberPropertyDetail = {
  createdAt: Scalars['String']['output'];
  employmentTypeId: Scalars['Int']['output'];
  employmentTypeName: Scalars['String']['output'];
  employmentTypeSelf: Scalars['String']['output'];
  id: Scalars['String']['output'];
  propertyId: Scalars['Int']['output'];
  propertyName: Scalars['String']['output'];
  propertySelf: Scalars['String']['output'];
  roles?: Maybe<Array<TeamMemberRole>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TeamMemberRole = {
  createdAt: Scalars['String']['output'];
  deptId: Scalars['Int']['output'];
  deptName: Scalars['String']['output'];
  deptSelf: Scalars['String']['output'];
  id: Scalars['String']['output'];
  roleId: Scalars['Int']['output'];
  roleName: Scalars['String']['output'];
  roleSelf: Scalars['String']['output'];
  roleStatus: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TimelineJobFilters = {
  currentTime: Scalars['String']['input'];
  date: Scalars['String']['input'];
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone: Scalars['String']['input'];
  userTimezone: Scalars['String']['input'];
};

export type TimelineFilterResults = {
  departmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  employeeIds?: Maybe<Array<Scalars['Int']['output']>>;
  locationIds?: Maybe<Array<Scalars['Int']['output']>>;
  results: Scalars['Int']['output'];
  roleIds?: Maybe<Array<Scalars['Int']['output']>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type Timeline = {
  groups?: Maybe<Array<TimelineGroup>>;
  id: Scalars['Int']['output'];
  teamMembers?: Maybe<Array<TimelineTeamMember>>;
};

export type TimelineGroup = {
  credits: Scalars['Float']['output'];
  departmentCode: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roleId: Scalars['Int']['output'];
  roleName: Scalars['String']['output'];
};

export type TimelineTeamMember = {
  employeeFirstName: Scalars['String']['output'];
  employeeId: Scalars['Int']['output'];
  employeeLastName: Scalars['String']['output'];
  employeeUsername: Scalars['String']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['String']['output'];
  items?: Maybe<Array<TimelineJob>>;
  name: Scalars['String']['output'];
};

export type TimelineJob = {
  end: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAutoIncremet?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  object?: Maybe<TimelineJobObject>;
  start: Scalars['String']['output'];
};

export type TimelineJobObject = {
  action?: Maybe<Scalars['String']['output']>;
  cleaningExceptionStatus?: Maybe<CleaningExceptionStatus>;
  cleaningStatus?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  credits?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  employeeId?: Maybe<Scalars['Int']['output']>;
  hasChecklist?: Maybe<Scalars['Boolean']['output']>;
  hasNotes?: Maybe<Scalars['Boolean']['output']>;
  hasPhotos?: Maybe<Scalars['Boolean']['output']>;
  isArrival?: Maybe<Scalars['Boolean']['output']>;
  isDeparture?: Maybe<Scalars['Boolean']['output']>;
  isInHouse?: Maybe<Scalars['Boolean']['output']>;
  isRush?: Maybe<Scalars['Boolean']['output']>;
  isVip?: Maybe<Scalars['Boolean']['output']>;
  jobId: Scalars['Int']['output'];
  occupancyStatus?: Maybe<Scalars['String']['output']>;
  outOfOrderDates?: Maybe<Array<Maybe<OutOfOrderDate>>>;
  reservationStatus?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusLabel?: Maybe<Scalars['String']['output']>;
};

export type UnassignedJobFilters = {
  category?: InputMaybe<UnassignedJobCategory>;
  currentTime: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export enum UnassignedJobCategory {
  Cleaning = 'CLEANING',
  Inspection = 'INSPECTION'
}

export type UnassignedJob = {
  action?: Maybe<Scalars['String']['output']>;
  arrivals?: Maybe<Scalars['String']['output']>;
  cleaningExceptionStatus?: Maybe<CleaningExceptionStatus>;
  cleaningStatus?: Maybe<Scalars['String']['output']>;
  credits?: Maybe<Scalars['Float']['output']>;
  etd?: Maybe<Scalars['String']['output']>;
  hasArrivalVip?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isArrival?: Maybe<Scalars['Boolean']['output']>;
  isDeparture?: Maybe<Scalars['Boolean']['output']>;
  isInHouse?: Maybe<Scalars['Boolean']['output']>;
  isReservation?: Maybe<Scalars['Boolean']['output']>;
  isRoom?: Maybe<Scalars['Boolean']['output']>;
  isRush?: Maybe<Scalars['Boolean']['output']>;
  isVip?: Maybe<Scalars['String']['output']>;
  occupancyStatus?: Maybe<Scalars['String']['output']>;
  outOfOrderDates?: Maybe<Array<Maybe<OutOfOrderDate>>>;
  room?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  adhocJobPatch: AdhocJob;
  assignProjectCycleJob: ProjectCycleJob;
  bulkAssignProjectCycleJobs?: Maybe<Scalars['String']['output']>;
  bulkAssignVirtualCycleJobs?: Maybe<Scalars['String']['output']>;
  convertTaskToJob?: Maybe<ConvertTaskToJobOutput>;
  copyChecklistTemplate: ChecklistTemplate;
  createAsset: Asset;
  createAssetType: AssetType;
  createBrand: Brand;
  createBulkAsset?: Maybe<Array<Maybe<Asset>>>;
  createBulkInvitation?: Maybe<CreateBulkInvitationOutput>;
  createChecklistTemplate: ChecklistTemplate;
  createNotificationGroup?: Maybe<Notification>;
  createProject: Project;
  createProjectV2: Project;
  createRepeatingJob: RepeatingJob;
  createTwilioToken: TwilioToken;
  deleteChecklistTemplate?: Maybe<Scalars['Boolean']['output']>;
  deleteNotificationGroup?: Maybe<Scalars['ID']['output']>;
  deleteProjectCycleJobs?: Maybe<Scalars['String']['output']>;
  deleteRepeatingJob?: Maybe<Scalars['String']['output']>;
  housekeepingJobDuration?: Maybe<HousekeepingJobDuration>;
  inspectionFailed: AdhocJob;
  inspectionSuccess: AdhocJob;
  jobSuggestion?: Maybe<JobSuggestion>;
  predictJobDuration: JobDuration;
  reassignJob: Scalars['Boolean']['output'];
  reassignMultiplesJobs: Scalars['Boolean']['output'];
  registerDevice?: Maybe<Device>;
  removeAdhocJobNote: AdhocJobNoteMutationOutput;
  removeAsset?: Maybe<Scalars['Int']['output']>;
  removeAssetType?: Maybe<Scalars['Int']['output']>;
  removeBrand?: Maybe<Brand>;
  removeProject?: Maybe<Project>;
  removeProjectCycle?: Maybe<Scalars['String']['output']>;
  removeVirtualProjectCycle: ProjectCycle;
  sendBulkInvitation?: Maybe<CreateBulkInvitationOutput>;
  startAutoAssign?: Maybe<Scalars['Boolean']['output']>;
  suggestion: ChatJobSuggestion;
  toggleRushJob: Scalars['Boolean']['output'];
  unassignJob: Scalars['Boolean']['output'];
  undeleteProjectCycleJobs?: Maybe<Scalars['String']['output']>;
  updateAdhocJob: AdhocJob;
  updateAdhocJobNote: AdhocJobNoteMutationOutput;
  updateAsset: Asset;
  updateAssetType: AssetType;
  updateAutoAssignmentConfigs: AutoAssignmentConfigs;
  updateBrand: Brand;
  updateChecklistTask: ChecklistTemplateTask;
  updateChecklistTemplate: ChecklistTemplate;
  updateCleaningExceptionStatus: CleaningExceptionResult;
  updateConfiguration: UpdateConfigurationResult;
  updateNotificationGroup?: Maybe<Notification>;
  updateProject: Project;
  updateProjectCycle: ProjectCycle;
  updateProjectV2: Project;
  updateRepeatingJob: RepeatingJob;
  updateTimeline: Scalars['Boolean']['output'];
  updateVirtualProjectCycle: ProjectCycle;
};


export type MutationAdhocJobPatchArgs = {
  adhocJobPatchInput: AdhocJobPatchInput;
};


export type MutationAssignProjectCycleJobArgs = {
  assignProjectCycleJobInput: AssignProjectCycleJobInput;
  projectCycleId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationBulkAssignProjectCycleJobsArgs = {
  bulkAssignProjectCycleJobsInput: Array<InputMaybe<AssignProjectCycleJobInput>>;
  projectCycleId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationBulkAssignVirtualCycleJobsArgs = {
  bulkAssignVirtualCycleJobsInput: Array<InputMaybe<AssignProjectCycleJobInput>>;
  frequencyHash: Scalars['String']['input'];
  projectCycleHash: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationConvertTaskToJobArgs = {
  checklistTaskId: Scalars['ID']['input'];
  convertTaskToJobInput?: InputMaybe<ConvertTaskToJobInput>;
};


export type MutationCopyChecklistTemplateArgs = {
  copyChecklistTemplateInput: CopyChecklistTemplateInput;
  id: Scalars['ID']['input'];
};


export type MutationCreateAssetArgs = {
  createAssetInput: CreateAssetInput;
};


export type MutationCreateAssetTypeArgs = {
  createAssetTypeInput: CreateAssetTypeInput;
};


export type MutationCreateBrandArgs = {
  createBrandInput: CreateBrandInput;
};


export type MutationCreateBulkAssetArgs = {
  createBulkAsset: CreateBulkAssetInput;
};


export type MutationCreateBulkInvitationArgs = {
  createBulkInvitationInput?: InputMaybe<CreateBulkInvitationInput>;
};


export type MutationCreateChecklistTemplateArgs = {
  createChecklistTemplateInput: CreateChecklistTemplateInput;
};


export type MutationCreateNotificationGroupArgs = {
  input: NotificationInput;
};


export type MutationCreateProjectArgs = {
  createProjectInput: CreateProjectInput;
};


export type MutationCreateProjectV2Args = {
  input: CreateProjectInputV2;
};


export type MutationCreateRepeatingJobArgs = {
  createRepeatingJobInput: CreateRepeatingJobInput;
};


export type MutationCreateTwilioTokenArgs = {
  createTwilioTokenInput: CreateTwilioTokenInput;
};


export type MutationDeleteChecklistTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProjectCycleJobsArgs = {
  jobCardHashes: Array<Scalars['String']['input']>;
  projectCycleId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationDeleteRepeatingJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHousekeepingJobDurationArgs = {
  jobDurationInput: HousekeepingJobDurationInput;
};


export type MutationInspectionFailedArgs = {
  assigneeId?: InputMaybe<Scalars['Int']['input']>;
  dutationInSeconds?: InputMaybe<Scalars['Int']['input']>;
  jobID?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  skipCheck?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationInspectionSuccessArgs = {
  jobID?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationJobSuggestionArgs = {
  jobCheck: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};


export type MutationPredictJobDurationArgs = {
  jobDurationInput: JobDurationInput;
};


export type MutationReassignJobArgs = {
  job: TimelineJobReassign;
};


export type MutationReassignMultiplesJobsArgs = {
  jobs: TimelineMultiplesJobsReassign;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRemoveAdhocJobNoteArgs = {
  adhocJobNoteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveAssetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveAssetTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveBrandArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveProjectCycleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveVirtualProjectCycleArgs = {
  frequencyHash: Scalars['String']['input'];
  projectCycleHash: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationSendBulkInvitationArgs = {
  sendBulkInvitationInput?: InputMaybe<SendBulkInvitationInput>;
};


export type MutationStartAutoAssignArgs = {
  jobs: Array<Scalars['String']['input']>;
};


export type MutationSuggestionArgs = {
  jobCheck?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationToggleRushJobArgs = {
  job: TimelineJobToggleRush;
};


export type MutationUnassignJobArgs = {
  job: TimelineJobUnassign;
};


export type MutationUndeleteProjectCycleJobsArgs = {
  jobCardHashes: Array<Scalars['String']['input']>;
  projectCycleId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationUpdateAdhocJobArgs = {
  adhocJobInput: AdhocJobInput;
};


export type MutationUpdateAdhocJobNoteArgs = {
  adhocJobNoteId?: InputMaybe<Scalars['String']['input']>;
  adhocJobNoteInput?: InputMaybe<AdhocJobNoteInput>;
};


export type MutationUpdateAssetArgs = {
  updateAssetInput: UpdateAssetInput;
};


export type MutationUpdateAssetTypeArgs = {
  updateAssetTypeInput: UpdateAssetTypeInput;
};


export type MutationUpdateAutoAssignmentConfigsArgs = {
  autoAssignmentConfigsInput: AutoAssignmentConfigsInput;
};


export type MutationUpdateBrandArgs = {
  updateBrandInput: UpdateBrandInput;
};


export type MutationUpdateChecklistTaskArgs = {
  id: Scalars['ID']['input'];
  updateChecklistTaskInput: UpdateChecklistTaskInput;
};


export type MutationUpdateChecklistTemplateArgs = {
  id: Scalars['ID']['input'];
  updateChecklistTemplateInput: UpdateChecklistTemplateInput;
};


export type MutationUpdateCleaningExceptionStatusArgs = {
  cleaningExceptionInput: CleaningExceptionInput;
};


export type MutationUpdateConfigurationArgs = {
  configurationInput: UpdateConfigurationInput;
};


export type MutationUpdateNotificationGroupArgs = {
  id: Scalars['ID']['input'];
  input: NotificationInput;
};


export type MutationUpdateProjectArgs = {
  updateProjectInput: UpdateProjectInput;
};


export type MutationUpdateProjectCycleArgs = {
  updateProjectCycleInput: UpdateProjectCycleInput;
};


export type MutationUpdateProjectV2Args = {
  input: UpdateProjectInputV2;
};


export type MutationUpdateRepeatingJobArgs = {
  updateRepeatingJobInput: UpdateRepeatingJobInput;
};


export type MutationUpdateTimelineArgs = {
  timeline: TimelineUpdate;
};


export type MutationUpdateVirtualProjectCycleArgs = {
  frequencyHash: Scalars['String']['input'];
  projectCycleHash: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  updateVirtualProjectCycleInput: UpdateVirtualProjectCycleInput;
};

export type AdhocJobPatchInput = {
  data?: InputMaybe<Array<InputMaybe<PatchDataInput>>>;
  id: Scalars['Int']['input'];
};

export type PatchDataInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  op?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type AssignProjectCycleJobInput = {
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  jobCardHash?: InputMaybe<Scalars['String']['input']>;
};

export type ConvertTaskToJobInput = {
  assignee_employee_id?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  locations: Array<ConvertTaskJobLocationInput>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type ConvertTaskJobLocationInput = {
  locationId: Scalars['Int']['input'];
  locationSelf?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ConvertTaskToJobOutput = {
  new_job?: Maybe<AdhocJob>;
};

export type CopyChecklistTemplateInput = {
  creator?: InputMaybe<CopyChecklistTemplateCreatorInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tasks: Array<CopyChecklistTemplateTaskInput>;
};

export type CopyChecklistTemplateCreatorInput = {
  employeeId: Scalars['Int']['input'];
};

export type CopyChecklistTemplateTaskInput = {
  attachment?: InputMaybe<Array<Scalars['String']['input']>>;
  checklistTemplateId: Scalars['String']['input'];
  dataTemperatureScale?: InputMaybe<Scalars['String']['input']>;
  defaultValue?: InputMaybe<Scalars['JSON']['input']>;
  dropdownOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  ordering_value?: InputMaybe<Scalars['String']['input']>;
  orderingValue?: InputMaybe<Scalars['String']['input']>;
  parent_task_id?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  taskType: Scalars['String']['input'];
};

export type CreateAssetInput = {
  assetType: CreateAssetAssetTypeInput;
  documents?: InputMaybe<Array<InputMaybe<CreateAssetDocumentInput>>>;
  installationDate?: InputMaybe<Scalars['String']['input']>;
  location: CreateAssetLocation;
  notes?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<CreateAssetPurchaseInput>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  vendor?: InputMaybe<CreateAssetVendorInput>;
  warranty?: InputMaybe<CreateAssetWarrantyInput>;
};

export type CreateAssetAssetTypeInput = {
  id: Scalars['ID']['input'];
};

export type CreateAssetDocumentInput = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetLocation = {
  id: Scalars['ID']['input'];
};

export type CreateAssetPurchaseInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetVendorInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isUsedInMultipleAssets?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetWarrantyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  warrantyPeriodType?: InputMaybe<WarrantyPeriodType>;
};

export type CreateAssetTypeInput = {
  brand?: InputMaybe<CreateAssetTypeBrandInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  documents?: InputMaybe<Array<InputMaybe<CreateAssetTypeDocumentInput>>>;
  jobItem: CreateAssetTypeJobItemInput;
  lifeCycle?: InputMaybe<CreateAssetTypeLifeCycleInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  serviceCycle?: InputMaybe<CreateAssetTypeServiceCycleInput>;
  status?: InputMaybe<Status>;
};

export type CreateAssetTypeBrandInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAssetTypeDocumentInput = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetTypeJobItemInput = {
  id: Scalars['ID']['input'];
};

export type CreateAssetTypeLifeCycleInput = {
  lifeCyclePeriodType?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAssetTypeServiceCycleInput = {
  displayName: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBrandInput = {
  displayName: Scalars['String']['input'];
};

export type CreateBulkAssetInput = {
  assets?: InputMaybe<Array<InputMaybe<CreateAssetInput>>>;
};

export type CreateBulkInvitationInput = {
  invitations?: InputMaybe<Array<InputMaybe<InvitationInput>>>;
};

export type InvitationInput = {
  archivedAt?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  employmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  employmentTypeName?: InputMaybe<Scalars['String']['input']>;
  employmentTypeSelf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inviteSelf?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['Int']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  orgSelf?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<InvitationSvcRoleInput>>>;
  sendNow?: InputMaybe<Scalars['Boolean']['input']>;
  sendText?: InputMaybe<Scalars['Boolean']['input']>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  toSendAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userExists?: InputMaybe<Scalars['Boolean']['input']>;
  userFirstName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  userLastName?: InputMaybe<Scalars['String']['input']>;
  userPhone?: InputMaybe<Scalars['String']['input']>;
  userSelf?: InputMaybe<Scalars['String']['input']>;
};

export type InvitationSvcRoleInput = {
  deptId?: InputMaybe<Scalars['Int']['input']>;
  deptName?: InputMaybe<Scalars['String']['input']>;
  deptSelf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roleId?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  roleSelf?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBulkInvitationOutput = {
  invitations_error?: Maybe<Array<Maybe<InvitationError>>>;
  invitations_success?: Maybe<Array<Maybe<Invitation>>>;
};

export type InvitationError = {
  error?: Maybe<Scalars['String']['output']>;
  invite?: Maybe<Invitation>;
};

export type Invitation = {
  archivedAt?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  employmentTypeId?: Maybe<Scalars['Int']['output']>;
  employmentTypeName?: Maybe<Scalars['String']['output']>;
  employmentTypeSelf?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviteSelf?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['Int']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  orgSelf?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<InvitationSvcRole>>>;
  sendNow?: Maybe<Scalars['Boolean']['output']>;
  sendText?: Maybe<Scalars['Boolean']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  toSendAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userExists?: Maybe<Scalars['Boolean']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
  userLastName?: Maybe<Scalars['String']['output']>;
  userPhone?: Maybe<Scalars['String']['output']>;
  userSelf?: Maybe<Scalars['String']['output']>;
};

export type InvitationSvcRole = {
  deptId?: Maybe<Scalars['Int']['output']>;
  deptName?: Maybe<Scalars['String']['output']>;
  deptSelf?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  roleSelf?: Maybe<Scalars['String']['output']>;
};

export type CreateChecklistTemplateInput = {
  creator?: InputMaybe<CreateChecklistTemplateCreatorInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tasks: Array<CreateChecklistTemplateTaskInput>;
};

export type CreateChecklistTemplateCreatorInput = {
  employeeId: Scalars['Int']['input'];
};

export type CreateChecklistTemplateTaskInput = {
  attachment?: InputMaybe<Array<Scalars['String']['input']>>;
  checklistTemplateId: Scalars['String']['input'];
  dataTemperatureScale?: InputMaybe<Scalars['String']['input']>;
  defaultValue?: InputMaybe<Scalars['JSON']['input']>;
  dropdownOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  ordering_value?: InputMaybe<Scalars['String']['input']>;
  orderingValue?: InputMaybe<Scalars['String']['input']>;
  parent_task_id?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  taskType: Scalars['String']['input'];
};

export type NotificationInput = {
  displayName: Scalars['String']['input'];
  userEventActionRules: Array<InputMaybe<UserEventActionRulesInput>>;
};

export type UserEventActionRulesInput = {
  actions: Array<InputMaybe<UserActionsInput>>;
  event?: InputMaybe<UserEventInput>;
};

export type UserActionsInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  schedule?: InputMaybe<UserScheduleInput>;
  type?: InputMaybe<Array<InputMaybe<ActionType>>>;
};

export type UserScheduleInput = {
  offsetDuration?: InputMaybe<Scalars['String']['input']>;
  offsetType?: InputMaybe<UserOffsetType>;
};

export type UserEventInput = {
  conditions?: InputMaybe<Array<InputMaybe<UserEventCondition>>>;
  context?: InputMaybe<EventContext>;
  filters?: InputMaybe<Array<InputMaybe<UserFilterInput>>>;
  type?: InputMaybe<Array<InputMaybe<EventType>>>;
};

export type UserEventCondition = {
  id?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ConditionType>;
};

export type UserFilterInput = {
  Action?: InputMaybe<FilterInput>;
  Assignee?: InputMaybe<FilterInput>;
  DataTask?: InputMaybe<FilterInput>;
  DataTaskNull?: InputMaybe<FilterInput>;
  Department?: InputMaybe<FilterInput>;
  Priority?: InputMaybe<FilterInput>;
  ProjectId?: InputMaybe<FilterInput>;
  Roles?: InputMaybe<FilterInput>;
  Status?: InputMaybe<FilterInput>;
  TaskType?: InputMaybe<FilterInput>;
  TemplateTaskId?: InputMaybe<FilterInput>;
  Type?: InputMaybe<FilterInput>;
};

export type FilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  operator: Operator;
  property: Scalars['String']['input'];
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  projectEndDate?: InputMaybe<Scalars['String']['input']>;
  projectJobDetails?: InputMaybe<Array<InputMaybe<CreateProjectJobDetailsInput>>>;
  projectStartDate: Scalars['String']['input'];
  serviceCycle: CreateProjectServiceCycleInput;
  status: Status;
};

export type CreateProjectJobDetailsInput = {
  jobAssign?: InputMaybe<JobAssignInput>;
  jobCancellation?: InputMaybe<JobCancellationInput>;
  jobRules?: InputMaybe<Array<InputMaybe<CreateProjectJobRulesInput>>>;
  jobTemplate?: InputMaybe<CreateProjectJobTemplateInput>;
};

export type JobAssignInput = {
  assignableEmployeeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  autoAssignType: AutoAssignType;
};

export type JobCancellationInput = {
  autoCancelJobs: Scalars['Boolean']['input'];
  cancellationStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateProjectJobRulesInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<JobRuleOperator>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateProjectJobTemplateInput = {
  action?: InputMaybe<JobAction>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checklistTemplates?: InputMaybe<Array<InputMaybe<AdhocJobChecklistInput>>>;
  departments?: InputMaybe<Array<InputMaybe<DepartmentInput>>>;
  dueByTime?: InputMaybe<Scalars['String']['input']>;
  jobItem?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  priority: JobPriority;
  roles?: InputMaybe<Array<InputMaybe<RoleInput>>>;
  scheduleStartTimeAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobChecklistInput = {
  id: Scalars['ID']['input'];
};

export type DepartmentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type NoteInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type RoleInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProjectServiceCycleInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  type: ServiceCycleType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectInputV2 = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  doesNotRepeat?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutoAssign?: InputMaybe<Scalars['Boolean']['input']>;
  noRepeat?: InputMaybe<Scalars['Boolean']['input']>;
  projectEndDate?: InputMaybe<Scalars['String']['input']>;
  projectJobDetails: ProjectJobDetailsInputV2;
  projectStartDate: Scalars['String']['input'];
  serviceCycle: ServiceCycleInput;
};

export type ProjectJobDetailsInputV2 = {
  jobAssign?: InputMaybe<JobAssignInput>;
  jobCancellation?: InputMaybe<JobCancellationInput>;
  jobRules?: InputMaybe<JobRulesInputV2>;
  jobTemplate?: InputMaybe<JobTemplateInputV2>;
};

export type JobRulesInputV2 = {
  assets?: InputMaybe<Scalars['JSON']['input']>;
  assetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  serviceType?: InputMaybe<ServiceType>;
};

export enum ServiceType {
  AssetType = 'assetType',
  JobItem = 'jobItem'
}

export type JobTemplateInputV2 = {
  action?: InputMaybe<JobAction>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  checklistTemplates?: InputMaybe<Array<InputMaybe<AdhocJobChecklistInput>>>;
  departments: Array<InputMaybe<DepartmentInput>>;
  dueByTime?: InputMaybe<Scalars['String']['input']>;
  jobItem?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  priority: JobPriority;
  roles?: InputMaybe<Array<InputMaybe<RoleInput>>>;
  scheduleStartTimeAt?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentInput = {
  uid: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ServiceCycleInput = {
  monthlyType?: InputMaybe<Scalars['String']['input']>;
  type: ServiceCycleType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRepeatingJobInput = {
  cycle?: InputMaybe<CreateRepeatingJobCycleInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  jobTemplate?: InputMaybe<CreateRepeatingJobTemplateInput>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status: Status;
};

export type CreateRepeatingJobCycleInput = {
  displayName: Scalars['String']['input'];
  frequency?: InputMaybe<RepeatCycleCadence>;
  type: CycleType;
  value: Scalars['String']['input'];
};

export type CreateRepeatingJobTemplateInput = {
  action?: InputMaybe<JobAction>;
  assignee?: InputMaybe<AssigneeInput>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  checklistTemplates?: InputMaybe<Array<ChecklistTemplateInput>>;
  department?: InputMaybe<Array<InputMaybe<DepartmentInput>>>;
  dueByTime?: InputMaybe<Scalars['String']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<RepeatingJobItemsInput>>>;
  location?: InputMaybe<Array<InputMaybe<LocationInput>>>;
  metadata?: InputMaybe<MetadataInput>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  priority: JobPriority;
  roles?: InputMaybe<Array<InputMaybe<RoleInput>>>;
  scheduleStartTimeAt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<JobStatus>;
  type?: InputMaybe<JobType>;
};

export type AssigneeInput = {
  employeeId: Scalars['ID']['input'];
};

export type ChecklistTemplateInput = {
  id: Scalars['String']['input'];
};

export type RepeatingJobItemsInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LocationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MetadataInput = {
  publicAttributes?: InputMaybe<PublicAttributesInput>;
};

export type PublicAttributesInput = {
  asset?: InputMaybe<NestedIdInput>;
};

export type NestedIdInput = {
  id: Scalars['ID']['input'];
};

export type CreateTwilioTokenInput = {
  device: Scalars['String']['input'];
  identity: Scalars['String']['input'];
};

export type TwilioToken = {
  identity: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type HousekeepingJobDurationInput = {
  action?: InputMaybe<JobAction>;
  assigneeFirstName?: InputMaybe<Scalars['String']['input']>;
  assigneeId?: InputMaybe<Scalars['Int']['input']>;
  assigneeLastName?: InputMaybe<Scalars['String']['input']>;
  credits?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  jobNotes?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['Int']['input'];
  locationName: Scalars['String']['input'];
  roomTypeCode?: InputMaybe<Scalars['String']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
  roomTypeName?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['String']['input']>;
};

export type HousekeepingJobDuration = {
  duration: Scalars['Float']['output'];
  waitTime?: Maybe<Scalars['Float']['output']>;
};

export type JobSuggestion = {
  action?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  isJob: Scalars['Boolean']['output'];
  item?: Maybe<Scalars['String']['output']>;
  jobItemId?: Maybe<Scalars['String']['output']>;
  jobType?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type JobDurationInput = {
  assigneeId?: InputMaybe<Scalars['Int']['input']>;
  assigneeName?: InputMaybe<Scalars['String']['input']>;
  attachmentCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['Int']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  displayJob?: InputMaybe<Scalars['String']['input']>;
  jobAction?: InputMaybe<Scalars['String']['input']>;
  jobItem?: InputMaybe<Scalars['String']['input']>;
  jobPriority?: InputMaybe<Scalars['String']['input']>;
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  jobType?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<InputMaybe<JobDurationLocation>>>;
  noteCount?: InputMaybe<Scalars['Int']['input']>;
  propertyId?: InputMaybe<Scalars['Int']['input']>;
  reporterId?: InputMaybe<Scalars['Int']['input']>;
  reporterName?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type JobDurationLocation = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobDuration = {
  dueTime: Scalars['String']['output'];
  jobDuration: Scalars['Float']['output'];
  waitTime: Scalars['Float']['output'];
};

export type TimelineJobReassign = {
  employeeId: Scalars['Int']['input'];
  jobId: Scalars['Int']['input'];
};

export type TimelineMultiplesJobsReassign = {
  employeeFirstName: Scalars['String']['input'];
  employeeId: Scalars['Int']['input'];
  employeeLastName: Scalars['String']['input'];
  employeeUsername: Scalars['String']['input'];
  isFromUnassignedJobs?: InputMaybe<Scalars['Boolean']['input']>;
  jobIds: Array<Scalars['Int']['input']>;
};

export type RegisterDeviceInput = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type Device = {
  createdByUserId?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastModifiedByUserId?: Maybe<Scalars['Int']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
};

export type AdhocJobNoteMutationOutput = {
  commentor?: Maybe<AdhocJobNoteCommentor>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export type SendBulkInvitationInput = {
  invitations_code?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChatJobSuggestion = {
  action?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  isJob?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Scalars['String']['output']>;
  jobItemId?: Maybe<Scalars['String']['output']>;
  jobType?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type TimelineJobToggleRush = {
  employeeId: Scalars['Int']['input'];
  isRushed?: InputMaybe<Scalars['Boolean']['input']>;
  jobIds: Array<Scalars['Int']['input']>;
};

export type TimelineJobUnassign = {
  jobIds: Array<Scalars['Int']['input']>;
};

export type AdhocJobInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  archivedAt?: InputMaybe<Scalars['String']['input']>;
  assignedAt?: InputMaybe<Scalars['String']['input']>;
  assignee?: InputMaybe<AdhocJobEmployeeInput>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  checklist_templates?: InputMaybe<Array<InputMaybe<AdhocJobChecklistInput>>>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<AdhocJobDeptInput>>;
  id: Scalars['Int']['input'];
  isEscalated?: InputMaybe<Scalars['Boolean']['input']>;
  isRushed?: InputMaybe<Scalars['Boolean']['input']>;
  item: Scalars['String']['input'];
  location?: InputMaybe<Array<AdhocJobLocationInput>>;
  metadata?: InputMaybe<AdhocJobMetadataInput>;
  notes?: InputMaybe<AdhocJobNotesInput>;
  periods?: InputMaybe<Array<InputMaybe<AdhocJobPeriodInput>>>;
  priority: Scalars['String']['input'];
  reporter?: InputMaybe<AdhocJobEmployeeInput>;
  roles?: InputMaybe<Array<InputMaybe<AdhocJobRoleInput>>>;
  self?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  targetTime?: InputMaybe<Scalars['String']['input']>;
  totalCost?: InputMaybe<Scalars['Float']['input']>;
  totalDurationSecs?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobEmployeeInput = {
  employeeId: Scalars['Int']['input'];
  employeeSelf?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobDeptInput = {
  departmentCode?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['Int']['input']>;
  departmentName?: InputMaybe<Scalars['String']['input']>;
  departmentSelf?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['Int']['input'];
};

export type AdhocJobLocationInput = {
  id: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  locationSelf?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobMetadataInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  privateAttributes?: InputMaybe<Scalars['JSON']['input']>;
  publicAttributes?: InputMaybe<Scalars['JSON']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobNotesInput = {
  comentor?: InputMaybe<AdhocJobEmployeeInput>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobPeriodInput = {
  archivedAt?: InputMaybe<Scalars['String']['input']>;
  assignee?: InputMaybe<AdhocJobEmployeeInput>;
  assigneeId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  employeeSalary?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobStatus: Scalars['String']['input'];
  periodCost?: InputMaybe<Scalars['Float']['input']>;
  periodDurationSecs?: InputMaybe<Scalars['Int']['input']>;
  periodEnd?: InputMaybe<Scalars['String']['input']>;
  periodStart?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobRoleInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  roleId?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
  roleSelf?: InputMaybe<Scalars['String']['input']>;
};

export type AdhocJobNoteInput = {
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssetInput = {
  assetType: CreateAssetAssetTypeInput;
  documents?: InputMaybe<Array<InputMaybe<CreateAssetDocumentInput>>>;
  id: Scalars['ID']['input'];
  installationDate?: InputMaybe<Scalars['String']['input']>;
  location: CreateAssetLocation;
  notes?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<CreateAssetPurchaseInput>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  vendor?: InputMaybe<CreateAssetVendorInput>;
  version: Scalars['Int']['input'];
  warranty?: InputMaybe<CreateAssetWarrantyInput>;
};

export type UpdateAssetTypeInput = {
  brand?: InputMaybe<CreateAssetTypeBrandInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  documents?: InputMaybe<Array<InputMaybe<CreateAssetTypeDocumentInput>>>;
  id: Scalars['ID']['input'];
  jobItem: CreateAssetTypeJobItemInput;
  lifeCycle?: InputMaybe<CreateAssetTypeLifeCycleInput>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  serviceCycle?: InputMaybe<CreateAssetTypeServiceCycleInput>;
  status?: InputMaybe<Status>;
  version: Scalars['Int']['input'];
};

export type AutoAssignmentConfigsInput = {
  check: Array<InputMaybe<Scalars['String']['input']>>;
  clean: Array<InputMaybe<Scalars['String']['input']>>;
  correction: Array<InputMaybe<Scalars['String']['input']>>;
  departure: Array<InputMaybe<Scalars['String']['input']>>;
  exists: AutoAssignmentConfigsExistsInput;
  inspection: Array<InputMaybe<Scalars['String']['input']>>;
  stayover: Array<InputMaybe<Scalars['String']['input']>>;
  touchup: Array<InputMaybe<Scalars['String']['input']>>;
  turndown: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AutoAssignmentConfigsExistsInput = {
  check: Scalars['Boolean']['input'];
  clean: Scalars['Boolean']['input'];
  correction: Scalars['Boolean']['input'];
  departure: Scalars['Boolean']['input'];
  inspection: Scalars['Boolean']['input'];
  stayover: Scalars['Boolean']['input'];
  touchup: Scalars['Boolean']['input'];
  turndown: Scalars['Boolean']['input'];
};

export type UpdateBrandInput = {
  displayName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateChecklistTaskInput = {
  attachment?: InputMaybe<Array<Scalars['String']['input']>>;
  checklistId: Scalars['String']['input'];
  createdAt: Scalars['String']['input'];
  dataCheckboxChecked?: InputMaybe<Scalars['Boolean']['input']>;
  dataDropdownValue?: InputMaybe<Array<Scalars['String']['input']>>;
  dataNumerical?: InputMaybe<Scalars['Float']['input']>;
  dataOnOff?: InputMaybe<Scalars['Boolean']['input']>;
  dataPassFail?: InputMaybe<EnumPassFail>;
  dataTemperatureScale?: InputMaybe<EnumTemperatureScale>;
  dataTemperatureValue?: InputMaybe<Scalars['Float']['input']>;
  dataText?: InputMaybe<Scalars['String']['input']>;
  dataYesNo?: InputMaybe<EnumYesNo>;
  defaultValue?: InputMaybe<Scalars['JSON']['input']>;
  dropdownOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  fulfillmentAttachment?: InputMaybe<Array<Scalars['String']['input']>>;
  fulfillmentNote?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ordering_value?: InputMaybe<Scalars['String']['input']>;
  orderingValue?: InputMaybe<Scalars['String']['input']>;
  parent_task_id?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  taskType: Scalars['String']['input'];
  templateTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateChecklistTemplateInput = {
  createdAt: Scalars['String']['input'];
  creator: UpdateChecklistTemplateCreatorInput;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tasks: Array<UpdateChecklistTemplateTaskInput>;
};

export type UpdateChecklistTemplateCreatorInput = {
  createdAt: Scalars['String']['input'];
  employeeId: Scalars['Int']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  lastName: Scalars['String']['input'];
};

export type UpdateChecklistTemplateTaskInput = {
  attachment?: InputMaybe<Array<Scalars['String']['input']>>;
  checklistTemplateId: Scalars['String']['input'];
  dataTemperatureScale?: InputMaybe<Scalars['String']['input']>;
  defaultValue?: InputMaybe<Scalars['JSON']['input']>;
  dropdownOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  ordering_value?: InputMaybe<Scalars['String']['input']>;
  orderingValue?: InputMaybe<Scalars['String']['input']>;
  parent_task_id?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
  taskType: Scalars['String']['input'];
};

export type CleaningExceptionInput = {
  cleaningExceptionStatus?: InputMaybe<CleaningExceptionStatusInput>;
  locationId: Scalars['ID']['input'];
};

export type CleaningExceptionStatusInput = {
  doNotDisturb?: InputMaybe<Scalars['Boolean']['input']>;
  doubleLockActive?: InputMaybe<Scalars['Boolean']['input']>;
  noServiceRequired?: InputMaybe<Scalars['Boolean']['input']>;
  sleepOutNoShow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CleaningExceptionResult = {
  cleaningExceptionStatus?: Maybe<CleaningExceptionStatus>;
  locationId: Scalars['ID']['output'];
};

export type UpdateConfigurationInput = {
  isEnabled: Scalars['Boolean']['input'];
  triggerTime: Scalars['String']['input'];
};

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  projectEndDate?: InputMaybe<Scalars['String']['input']>;
  projectJobDetails?: InputMaybe<Array<InputMaybe<CreateProjectJobDetailsInput>>>;
  projectStartDate: Scalars['String']['input'];
  serviceCycle: CreateProjectServiceCycleInput;
  status: Status;
  version: Scalars['Int']['input'];
};

export type UpdateProjectCycleInput = {
  displayEndDate?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayStartDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  version: Scalars['Int']['input'];
};

export type UpdateProjectInputV2 = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  hasAutoAssign?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  noRepeat?: InputMaybe<Scalars['Boolean']['input']>;
  projectEndDate?: InputMaybe<Scalars['String']['input']>;
  projectJobDetails: ProjectJobDetailsInputV2;
  projectStartDate: Scalars['String']['input'];
  serviceCycle?: InputMaybe<ServiceCycleInput>;
  status: Status;
  version: Scalars['Int']['input'];
};

export type UpdateRepeatingJobInput = {
  cycle?: InputMaybe<CreateRepeatingJobCycleInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobTemplate?: InputMaybe<CreateRepeatingJobTemplateInput>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status: Status;
  version: Scalars['Int']['input'];
};

export type TimelineUpdate = {
  date: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  teamMembers?: InputMaybe<Array<TimelineUpdateTeamMember>>;
  unassignJobs?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type TimelineUpdateTeamMember = {
  creditQuota?: InputMaybe<Scalars['Float']['input']>;
  deptId: Scalars['Int']['input'];
  employeeId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  onTimeline?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  roleId: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  stopTime?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateVirtualProjectCycleInput = {
  displayEndDate?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayStartDate?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  housekeepingSub?: Maybe<HousekeepingSub>;
  jobUpdated?: Maybe<Job>;
  myAddedJobsSub?: Maybe<Job>;
  myTodayJobsHkSub?: Maybe<HousekeepingSub>;
  myTodayJobsSub?: Maybe<TimelineSub>;
  timelineJobsSub?: Maybe<TimelineSub>;
  unassignedJobsUpdated?: Maybe<Job>;
};


export type SubscriptionJobUpdatedArgs = {
  filters?: InputMaybe<JobUpdatedFilters>;
};


export type SubscriptionMyAddedJobsSubArgs = {
  filters?: InputMaybe<MyAddedJobsUpdatedFilters>;
};


export type SubscriptionMyTodayJobsSubArgs = {
  filters?: InputMaybe<MyTodayJobsUpdatedFilters>;
};


export type SubscriptionTimelineJobsSubArgs = {
  filters?: InputMaybe<TimelineSubFilters>;
};

export type HousekeepingSub = {
  payload?: Maybe<HousekeepingPayload>;
  topic?: Maybe<Scalars['String']['output']>;
};

export type HousekeepingPayload = GuestSubType | ReservationSubType | RoomCleaningExceptionStatusSubType | RoomCleaningStatusSubType | RoomOccupancyStatusSubType | RoomSubType | RoomSummarySubType;

export type GuestSubType = {
  Attributes?: Maybe<HkAttributes>;
  Audit?: Maybe<HkAudit>;
  ExternalGuestProfileId?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  IsDeleted?: Maybe<Scalars['Boolean']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  MiddleName?: Maybe<Scalars['String']['output']>;
  Preferences?: Maybe<Array<Maybe<HkPreferences>>>;
  Title?: Maybe<Scalars['String']['output']>;
};

export type HkAttributes = {
  AnyGuestParameter?: Maybe<Scalars['String']['output']>;
  BeddingConfig?: Maybe<Scalars['String']['output']>;
  BlockCode?: Maybe<Scalars['String']['output']>;
  BookingSource?: Maybe<Scalars['String']['output']>;
  Butler?: Maybe<Scalars['String']['output']>;
  ConfirmationNumber?: Maybe<Scalars['String']['output']>;
  GroupCode?: Maybe<Scalars['String']['output']>;
  HasPets?: Maybe<Scalars['String']['output']>;
  MarketSegmentCode?: Maybe<Scalars['String']['output']>;
  RateCode?: Maybe<Scalars['String']['output']>;
  SpecialTurndownRequired?: Maybe<Scalars['String']['output']>;
};

export type HkAudit = {
  Created?: Maybe<HkSubCreated>;
  LastModified?: Maybe<HkSubLastModified>;
};

export type HkSubCreated = {
  Timestamp?: Maybe<Scalars['String']['output']>;
  User?: Maybe<HkSubUser>;
};

export type HkSubUser = {
  Id?: Maybe<Scalars['Int']['output']>;
};

export type HkSubLastModified = {
  Timestamp?: Maybe<Scalars['String']['output']>;
  User?: Maybe<HkSubUser>;
};

export type HkPreferences = {
  Description?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

export type ReservationSubType = {
  ArrivalDate?: Maybe<Scalars['String']['output']>;
  Attributes?: Maybe<HkAttributes>;
  Audit?: Maybe<HkAudit>;
  DepartureDate?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  EstimatedArrivalTime?: Maybe<Scalars['String']['output']>;
  EstimatedDepartureTime?: Maybe<Scalars['String']['output']>;
  ExternalGroupReservationId?: Maybe<Scalars['String']['output']>;
  ExternalReservationId?: Maybe<Scalars['String']['output']>;
  GuestProfileIds?: Maybe<Array<Maybe<HkGuestProfileIds>>>;
  Id?: Maybe<Scalars['Int']['output']>;
  IsDeleted?: Maybe<Scalars['Boolean']['output']>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  NoOfAdults?: Maybe<Scalars['Int']['output']>;
  NoOfChildren?: Maybe<Scalars['Int']['output']>;
  Notes?: Maybe<Array<Maybe<HkNotesSub>>>;
  Preferences?: Maybe<Array<Maybe<HkPreferences>>>;
  ReservationStatus?: Maybe<Scalars['String']['output']>;
  StatusLabel?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

export type HkGuestProfileIds = {
  ExternalGuestProfileId?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
};

export type HkNotesSub = {
  Note?: Maybe<Scalars['String']['output']>;
};

export type RoomCleaningExceptionStatusSubType = {
  Audit?: Maybe<HkAudit>;
  DoNotDisturb?: Maybe<Scalars['Boolean']['output']>;
  DoubleLockActive?: Maybe<Scalars['Boolean']['output']>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  NoServiceRequired?: Maybe<Scalars['Boolean']['output']>;
  SleepOutNoShow?: Maybe<Scalars['Boolean']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

export type RoomCleaningStatusSubType = {
  Audit?: Maybe<HkAudit>;
  Id?: Maybe<Scalars['Int']['output']>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

export type RoomOccupancyStatusSubType = {
  Audit?: Maybe<HkAudit>;
  Id?: Maybe<Scalars['Int']['output']>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

export type RoomSubType = {
  Id?: Maybe<Scalars['Int']['output']>;
  Location?: Maybe<HkSubLocation>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  OutOfOrderDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  Reservations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HkSubLocation = {
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  IsVirtual?: Maybe<Scalars['String']['output']>;
  LocationType?: Maybe<HkSubLocationType>;
  LocationTypeId?: Maybe<Scalars['Int']['output']>;
  LongDisplayName?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Notes?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['String']['output']>;
  RoomCleaningExceptionStatus?: Maybe<Scalars['String']['output']>;
  RoomCleaningStatus?: Maybe<Scalars['String']['output']>;
  RoomOccupancyStatus?: Maybe<Scalars['String']['output']>;
  RoomType?: Maybe<HkSubRoomType>;
  RoomTypeId?: Maybe<Scalars['Int']['output']>;
  ShortDisplayName?: Maybe<Scalars['String']['output']>;
  SquareUnit?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Scalars['String']['output']>;
  VirtualChildrenLocationIds?: Maybe<Scalars['String']['output']>;
};

export type HkSubLocationType = {
  Code?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  Note?: Maybe<Scalars['String']['output']>;
  SquareUnit?: Maybe<Scalars['String']['output']>;
};

export type HkSubRoomType = {
  Code?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  Note?: Maybe<Scalars['String']['output']>;
};

export type RoomSummarySubType = {
  CleaningExceptionStatus?: Maybe<CleaningExceptionStatus>;
  CleaningStatus?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  IsVirtual?: Maybe<Scalars['String']['output']>;
  LocationId?: Maybe<Scalars['Int']['output']>;
  LocationType?: Maybe<HkSubLocationType>;
  LongDisplayName?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Notes?: Maybe<Scalars['String']['output']>;
  OccupancyStatus?: Maybe<Scalars['String']['output']>;
  OutOfOrderDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ParentId?: Maybe<Scalars['String']['output']>;
  Reservations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  RoomType?: Maybe<HkSubRoomType>;
  ShortDisplayName?: Maybe<Scalars['String']['output']>;
  SquareUnit?: Maybe<Scalars['String']['output']>;
  Tags?: Maybe<Scalars['String']['output']>;
  VirtualChildrenLocationIds?: Maybe<Scalars['String']['output']>;
};

export type JobUpdatedFilters = {
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  globalSearch?: InputMaybe<Scalars['String']['input']>;
};

export type MyAddedJobsUpdatedFilters = {
  employeeId: Scalars['ID']['input'];
};

export type MyTodayJobsUpdatedFilters = {
  checksum: Scalars['Int']['input'];
  employeeId: Scalars['ID']['input'];
};

export type TimelineSub = {
  CreatedAt?: Maybe<Scalars['String']['output']>;
  Day?: Maybe<Scalars['String']['output']>;
  DeletedAt?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  TimelineEmployees?: Maybe<Array<Maybe<TimelineSubEmployees>>>;
  UpdatedAt?: Maybe<Scalars['String']['output']>;
};

export type TimelineSubEmployees = {
  CreatedAt?: Maybe<Scalars['String']['output']>;
  CreditQuota?: Maybe<Scalars['Int']['output']>;
  DeletedAt?: Maybe<Scalars['String']['output']>;
  DepartmentId?: Maybe<Scalars['Int']['output']>;
  EmployeeId?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['Int']['output']>;
  Jobs?: Maybe<Scalars['String']['output']>;
  JobsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  Locations?: Maybe<Scalars['String']['output']>;
  LocationTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  RoleId?: Maybe<Scalars['Int']['output']>;
  ShowOnTimeline?: Maybe<Scalars['Boolean']['output']>;
  TimelineId?: Maybe<Scalars['Int']['output']>;
  UpdatedAt?: Maybe<Scalars['String']['output']>;
  WorkingHourEnd?: Maybe<Scalars['String']['output']>;
  WorkingHourStart?: Maybe<Scalars['String']['output']>;
};

export type TimelineSubFilters = {
  timelineId: Scalars['Int']['input'];
};

export type Attribute = {
  groupId?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  traces?: Maybe<Scalars['String']['output']>;
};

export type BulkCreateAssetsResult = {
  result?: Maybe<Array<Maybe<Asset>>>;
};

export type JobDurationResult = {
  result?: Maybe<JobDuration>;
};

export type Response = {
  result?: Maybe<Scalars['String']['output']>;
};

export type UserId = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type ChecklistTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ChecklistTemplateQuery = { checklistTemplate?: { archivedAt?: string | null, createdAt?: string | null, id: string, name: string, description?: string | null, updatedAt?: string | null, tasks?: Array<{ attachment?: Array<string | null> | null, checklistTemplateId?: string | null, createdAt?: string | null, id: string, label?: string | null, updatedAt?: string | null, taskType?: string | null, required?: boolean | null, parent_task_id?: string | null, ordering_value?: string | null, dataTemperatureScale?: EnumTemperatureScale | null, dataDropdownValue?: Array<string> | null, dropdownOptions?: Array<string> | null, defaultValue?: any | null, note?: string | null }> | null, creator: { employeeId?: string | null, firstName?: string | null, id?: string | null, lastName?: string | null, createdAt?: string | null } } | null };

export type ChecklistTemplatesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChecklistTemplatesQuery = { page: { totalCount: number, edges?: Array<{ cursor: string, node: { archivedAt?: string | null, createdAt?: string | null, id: string, name: string, description?: string | null, updatedAt?: string | null, tasks?: Array<{ attachment?: Array<string | null> | null, checklistTemplateId?: string | null, createdAt?: string | null, id: string, label?: string | null, taskType?: string | null, updatedAt?: string | null, required?: boolean | null, ordering_value?: string | null, parent_task_id?: string | null, note?: string | null }> | null, creator: { firstName?: string | null, lastName?: string | null } } | {} }> | null, pageInfo: { endCursor: string, hasNextPage: boolean } } };


export const ChecklistTemplateDocument = gql`
    query ChecklistTemplate($id: ID!) {
  checklistTemplate(id: $id) {
    archivedAt
    createdAt
    id
    tasks {
      attachment
      checklistTemplateId
      createdAt
      id
      label
      note: notes
      updatedAt
      taskType
      required
      parent_task_id
      ordering_value
      dataTemperatureScale
      dataDropdownValue
      dropdownOptions
      defaultValue
    }
    creator {
      employeeId
      firstName
      id
      lastName
      createdAt
    }
    name
    description
    updatedAt
  }
}
    `;

/**
 * __useChecklistTemplateQuery__
 *
 * To run a query within a React component, call `useChecklistTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChecklistTemplateQuery(baseOptions: Apollo.QueryHookOptions<ChecklistTemplateQuery, ChecklistTemplateQueryVariables> & ({ variables: ChecklistTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>(ChecklistTemplateDocument, options);
      }
export function useChecklistTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>(ChecklistTemplateDocument, options);
        }
export function useChecklistTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>(ChecklistTemplateDocument, options);
        }
export type ChecklistTemplateQueryHookResult = ReturnType<typeof useChecklistTemplateQuery>;
export type ChecklistTemplateLazyQueryHookResult = ReturnType<typeof useChecklistTemplateLazyQuery>;
export type ChecklistTemplateSuspenseQueryHookResult = ReturnType<typeof useChecklistTemplateSuspenseQuery>;
export type ChecklistTemplateQueryResult = Apollo.QueryResult<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>;
export const ChecklistTemplatesDocument = gql`
    query ChecklistTemplates($first: Int, $cursor: String, $orderBy: String, $filters: String) {
  page: checklistTemplates(first: $first, after: $cursor, orderBy: $orderBy, filters: $filters) {
    totalCount
    edges {
      node {
        ... on ChecklistTemplate {
          archivedAt
          createdAt
          id
          tasks {
            attachment
            checklistTemplateId
            createdAt
            id
            label
            taskType
            updatedAt
            required
            ordering_value
            parent_task_id
            note: notes
          }
          creator {
            firstName
            lastName
          }
          name
          description
          updatedAt
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useChecklistTemplatesQuery__
 *
 * To run a query within a React component, call `useChecklistTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useChecklistTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>(ChecklistTemplatesDocument, options);
      }
export function useChecklistTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>(ChecklistTemplatesDocument, options);
        }
export function useChecklistTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>(ChecklistTemplatesDocument, options);
        }
export type ChecklistTemplatesQueryHookResult = ReturnType<typeof useChecklistTemplatesQuery>;
export type ChecklistTemplatesLazyQueryHookResult = ReturnType<typeof useChecklistTemplatesLazyQuery>;
export type ChecklistTemplatesSuspenseQueryHookResult = ReturnType<typeof useChecklistTemplatesSuspenseQuery>;
export type ChecklistTemplatesQueryResult = Apollo.QueryResult<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>;