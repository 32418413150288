import { Button } from '@optii/ui-library';
import React, { useState } from 'react';

import { ChecklistApp } from '@optii/checklists';

export function ChecklistsManagement() {
  const [open, setOpen] = useState(false);

  console.log(open);
  return (
    <div>
      Hello World, this is the Checklist,
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Checklist
      </Button>
      {open ? <ChecklistApp modalMode add setOpenModal={setOpen} /> : null}
    </div>
  );
}
