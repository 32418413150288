import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Loading, TopModalContent } from '@optii/shared';
import { useChecklistTemplateQuery } from '@optii/global';

import { ChecklistModalContext } from '../contexts';
import {
  Container,
  Description,
  InnerTitle,
  SubTitle,
  TemplateMessage,
} from '..';
import CheckListTemplate from './template/CheckListTemplate';

const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

const dateFormat = new Intl.DateTimeFormat('default', options as any);

export default function ReadOnlyChecklistTemplate() {
  const { t } = useTranslation(['common', 'checklists']);
  const { data: contextData, setData } = useContext(ChecklistModalContext);

  const [query] = useQueryParams({
    openChecklistTemplate: StringParam,
  });

  const { data: { checklistTemplate } = {}, loading } =
    useChecklistTemplateQuery({
      variables: {
        id: query.openChecklistTemplate || '',
      },
      context: { _instance: 'node' },
      skip: !!contextData,
    });

  if (!contextData && checklistTemplate) setData(checklistTemplate);
  if (loading) {
    return <Loading loading modifiers={['relative']} />;
  }
  return (
    <>
      <TopModalContent>
        <Description>{contextData?.description}</Description>
        {contextData && (
          <SubTitle>
            {t(
              'checklist: Added by {{firstName}} {{lastName}} on {{addedDate}}',
              {
                firstName: contextData.creator.firstName,
                lastName: contextData.creator.lastName,
                addedDate: dateFormat.format(new Date(contextData.createdAt)),
              },
            )}
          </SubTitle>
        )}
        <TemplateMessage data-testid="checklist-template-edit-message">
          {t(
            'checklist:This is a template. To use this checklist, attach it to a job.',
          )}
        </TemplateMessage>
        <InnerTitle>{t('checklist: Tasks')}</InnerTitle>
      </TopModalContent>
      {!contextData?.tasks && (
        <p>{t('checklist: No tasks on this checklist')}</p>
      )}
      <Container data-testid="checklist-template">
        <CheckListTemplate
          isScoringEnabled={contextData?.enableScoring}
          isReadOnly
          data={contextData?.tasks}
          draggable={false}
          footer={undefined}
        />
      </Container>
    </>
  );
}
