import React, { useContext, useMemo } from 'react';

import {
  getEnvironment,
  GA_EVENTS,
  GoogleAnalyticsClient,
} from '@optii/shared';
import styled from 'styled-components/macro';
import { FlexContainer, FlexItem } from '../../elements';
import { ChecklistItemContext } from '../../contexts';
import { CHECKLIST_TASK_FIELDS } from '../../taskConstants';
import TaskNameField from '../TaskNameField';
import SelectType from '../SelectType';

export const RequiredFlag = styled.div`
  padding-right: 0.8rem;
  &:before {
    content: '\\002A';
    color: ${(props) => props.theme.colors.rose};
    font-size: 1.4rem;
    display: block;
    height: 1rem;
    margin-top: 0.7rem;
  }
`;

const OPTION_STATUS = {
  idle: 'idle',
  selected: 'selected',
  notSelected: 'notSelected',
};

const CustomRequiredFlag = styled(RequiredFlag as 'div')`
  position: absolute;
  margin-left: -14px;
  margin-top: 0.9rem;
`;

const OptionButton = styled.button<{
  spacedOptions?: boolean;
  status?: string;
}>`
  border: 0.1rem solid ${(props) => props.theme.colors.charcoal02};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.type.primary}
  ${(props) => props.theme.fonts.weights.medium}
  cursor: pointer;
  text-align: center;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  border-radius: 0.5rem;

  ${({ spacedOptions }) => {
    if (!spacedOptions) {
      return `
        border-radius: 0;
        border-right-width: 0;
        &:first-of-type {
          border-radius: 0.5rem 0 0 0.5rem;
          border-right-width: 0;
          border-left-width: 0.1rem;
        }
        &:last-of-type {
          border-radius: 0 0.5rem 0.5rem 0;
          border-left-width: 0.1rem;
          border-right-width: 0.1rem;
        }
      `;
    }

    return '';
  }}

  ${({ status, theme }) => {
    if (status === OPTION_STATUS.selected) {
      return `
        color: ${theme.colors.white};
        background: ${theme.colors.charcoalDefault};
      `;
    }

    if (status === OPTION_STATUS.idle) {
      return `
        color: ${theme.colors.charcoal05};
        background: ${theme.colors.white};
      `;
    }

    return `
      color: ${theme.colors.charcoal03};
      background: ${theme.colors.white};
    `;
  }}
`;

function OptionType({ options, fieldName, spacedOptions }: any) {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    isFulfillment,
    formValues,
    isReadOnly,
    onFormChange,
  } = useContext(ChecklistItemContext);

  const isRequired =
    String(
      formValues[CHECKLIST_TASK_FIELDS.required as keyof typeof formValues],
    ) === 'true';

  const disabled = !isFulfillment ? isReadOnly : isFulfillment;

  const isIdle = useMemo(
    () =>
      isFulfillment
        ? fulfillmentValues[fieldName as keyof typeof fulfillmentValues] ===
            null ||
          fulfillmentValues[fieldName as keyof typeof fulfillmentValues] ===
            undefined
        : formValues?.defaultValue?.value === null ||
          formValues?.defaultValue?.value === undefined,
    [
      fulfillmentValues,
      fieldName,
      formValues?.defaultValue?.value,
      isFulfillment,
    ],
  );

  const isSelected = (option: any) =>
    isFulfillment
      ? fulfillmentValues[fieldName as keyof typeof fulfillmentValues] ===
        option.value
      : formValues?.defaultValue?.value === option.value;

  const getStatus = (option: any) => {
    if (isIdle) return OPTION_STATUS.idle;
    if (isSelected(option)) return OPTION_STATUS.selected;
    return OPTION_STATUS.notSelected;
  };

  const getLabel = (option: any) => {
    const status = getStatus(option);
    if (!option.labelConfig) return option.label;

    const customLabel = option.labelConfig[status];
    if (customLabel) return customLabel;

    return option.label;
  };

  const getStyles = (option: any) => {
    let styles;
    try {
      const status = getStatus(option);
      styles = option.stylesConfig[status];
    } catch (error) {
      styles = {};
    }
    return styles;
  };

  const typeCheck = (newValue: any) => {
    switch (newValue) {
      case true:
        GoogleAnalyticsClient.event(GA_EVENTS.onOffTaskSelectOn);
        break;
      case false:
        GoogleAnalyticsClient.event(GA_EVENTS.onOffTaskSelectOff);
        break;
      case 'PASSED':
        GoogleAnalyticsClient.event(GA_EVENTS.passFailTaskSelectPassed);
        break;
      case 'ERROR':
        GoogleAnalyticsClient.event(GA_EVENTS.passFailTaskSelectFailed);
        break;
      case 'YES':
        GoogleAnalyticsClient.event(GA_EVENTS.yesNoTaskSelectYes);
        break;
      case 'NO':
        GoogleAnalyticsClient.event(GA_EVENTS.yesNoTaskSelectNo);
        break;
      case null:
        GoogleAnalyticsClient.event(GA_EVENTS.onOffTaskDeselect);
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (option: any) => {
    const EMPTY_OPTION_VALUE = null;
    const oldValue = isFulfillment
      ? fulfillmentValues[fieldName as keyof typeof fulfillmentValues]
      : formValues?.defaultValue?.value;
    let newValue = option.value;
    if (newValue === oldValue) {
      newValue = EMPTY_OPTION_VALUE;
    }
    typeCheck(newValue);

    if (isFulfillment) {
      onFulfillmentChange({
        ...formValues,
        [fieldName]: newValue,
      });
    }

    onFormChange({
      name: 'defaultValue',
      value: {
        value: newValue,
      },
    });

    return newValue;
  };

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container" col>
      <FlexContainer>
        <FlexItem>
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer colGap={spacedOptions ? '1rem' : '0rem'}>
        {isRequired && <CustomRequiredFlag />}

        {options?.map((option: any, index: number) => (
          <OptionButton
            key={option.value}
            status={getStatus(option)}
            onClick={() => handleOptionChange(option)}
            disabled={disabled}
            value={
              !isFulfillment
                ? formValues?.defaultValue?.value
                : fulfillmentValues[fieldName as keyof typeof fulfillmentValues]
            }
            spacedOptions={spacedOptions}
            style={getStyles(option)}
          >
            {getLabel(option)}
          </OptionButton>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
}

export default OptionType;
