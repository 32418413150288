import React, { Dispatch, SetStateAction } from 'react';
import { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';

type ChecklistModalContextType = {
  close: ({ refresh }: { refresh?: string }) => void;
  open: Dispatch<SetStateAction<boolean>>;
  openChild: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  isChildOpen: boolean;
  readOnly: boolean;
  isReadOnly: boolean | '' | undefined | null;
  isAdd: boolean;
  isEdit: boolean;
  isCopy: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  data: any;
  isFulfillment: string | boolean | null | undefined;
  setData: Dispatch<SetStateAction<any>>;
  query: DecodedValueMap<{
    edit: QueryParamConfig<
      boolean | null | undefined,
      boolean | null | undefined
    >;
    readOnlyChecklist: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    copy: QueryParamConfig<
      boolean | null | undefined,
      boolean | null | undefined
    >;
    openJob: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    openChecklistTemplate: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    fulfillment: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    refresh: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
  }>;
  setQuery: SetQuery<{
    edit: QueryParamConfig<
      boolean | null | undefined,
      boolean | null | undefined
    >;
    copy: QueryParamConfig<
      boolean | null | undefined,
      boolean | null | undefined
    >;
    readOnlyChecklist: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    openJob: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    openChecklistTemplate: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    fulfillment: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
    refresh: QueryParamConfig<
      string | null | undefined,
      string | null | undefined
    >;
  }>;
  modalMode: boolean;
};

export const ChecklistModalContext =
  React.createContext<ChecklistModalContextType>({
    close: ({ refresh }: { refresh?: string }) => {
      // close
    },
    open: () => {
      // open
    },
    openChild: () => {
      // open child drawer
    },
    isChildOpen: false,
    isOpen: false,
    readOnly: false,
    isReadOnly: false,
    isAdd: false,
    isEdit: false,
    isCopy: false,
    loading: true,
    setLoading: () => {
      // set Loading state
    },
    isFulfillment: false,
    setData: () => {
      // setData state
    },
    query: {
      copy: null,
      edit: undefined,
      fulfillment: undefined,
      openChecklistTemplate: undefined,
      readOnlyChecklist: undefined,
      openJob: undefined,
      refresh: undefined,
    },
    setQuery: () => {},
    data: [],
    modalMode: false,
  });

export const ChecklistItemContext = React.createContext({
  formValues: {
    label: '',
    value: '',
    taskType: 'checkbox',
    defaultValue: {
      value: '',
      scale: '',
    },
  },
  isDefaultValueAllowed: false,
  touched: {},
  actionState: '',
  setActionState: (a: any) => {
    // set action
  },
  fulfillmentValues: {},
  groupId: '',
  onFormChange: (a: any) => {
    // onForm change handler
  },
  onFulfillmentChange: (a: any) => {
    // on fulfillment change
  },
  isValid: true,
  errors: {
    label: '',
  },
  readOnly: false,
  isReadOnly: false,
  isEdit: false,
  isAdd: false,
  isFulfillment: false,
  isOpen: false,
  toggleOpen: () => {
    // open state
  },
  onToggleOpen: (a: any) => {
    // open function
  },
});
