import { isPlatform } from '@ionic/react';
import styled from 'styled-components';

function capacitorSpaceModifier(previous, offset = '2.5rem') {
  return isPlatform('capacitor') ? `calc(${previous} + ${offset})` : previous;
}

export const Top = styled.div`
  display: flex;
  margin-top: ${capacitorSpaceModifier('0rem')};
  padding: 1.6rem 2.4rem;
  background-color: ${({ theme }) => theme.colors.charcoal01opct50};
  align-items: center;
  max-width: 100%;
`;

export const Status = styled.div`
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.medium};
  background-color: ${(props) => props.theme.colors.charcoal02};
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  margin-left: 0.8rem;
  ${(props) => props.theme.fonts.weights.medium};

  &.not_started {
    background-color: ${(props) => props.theme.colors.purpleXLight};
    color: ${(props) => props.theme.colors.purpleXDark};
  }
  &.in_progress {
    background-color: ${(props) => props.theme.colors.emeraldXXXLight};
    color: ${(props) => props.theme.colors.emeraldXDark};
  }
  &.completed {
    background-color: ${(props) => props.theme.colors.yellowLight};
    color: ${(props) => props.theme.colors.yellowDark};
  }
  &.cancelled {
  }
  &.on_hold {
    background-color: ${(props) => props.theme.colors.blueLight};
    color: ${(props) => props.theme.colors.blueDark};
  }
  &.new {
  }
  &.archived {
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TopModalWrapper = styled.div`
  padding: 0.1rem 0.8rem 2.4rem 0.8rem;
  ${(props) => (props.pb0 ? 'padding-bottom: 0' : '')};
  ${(props) => (props.pt0 ? 'padding-top: 0' : '')};
  background-color: ${(props) => props.theme.colors.charcoal01opct50};
`;

export const MiddleModalWrapper = styled.div`
  padding: 0;
  overflow-x: hidden;
  flex: 1;
  max-width: 100%;
`;
export const BottomModalWrapper = styled.div`
  padding: 2.4rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  background: ${(props) => props.theme.colors.white};
  bottom: 0;
  box-shadow: 0px -0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 999;
  margin-top: 2.4rem;
  position: sticky;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    .is-keyboard-open & {
      position: relative;
    }
  }
`;

export const TopModalContent = styled.div`
  border: 1px solid ${(props) => props.theme.colors.charcoal02};
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  padding: 1.6rem;
`;

export const Type = styled.div`
  ${(props) => props.theme.fonts.sizes.smallest};
  color: ${(props) => props.theme.fonts.weights.charcoal045};
  padding-bottom: 0.4rem;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    flex: 1;
    text-align: left;
    ${(props) => props.theme.fonts.type.primary}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    transform: rotate(180deg);
    margin-top: 0.5rem;
  }

  ${(props) =>
    props.isHeaderOpen &&
    `
    h3 {
      padding-bottom: 2.1rem;
      white-space: initial;
    }

    svg {
      transform: rotate(0);
    }

  `}
`;

export const IconContainer = styled.div`
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const ReadyOnlyField = styled.div`
  display: flex;
  gap: 1.8rem;
  margin-top: ${(props) => (props.hasMarginTop ? '1.8rem' : '0px')};
  margin-bottom: ${(props) => (props.smallMargin ? '.2rem' : '1.8rem')};
  align-items: center;
  label {
    color: ${({ theme }) => theme.colors.charcoal45};
  }
  input {
    color: ${({ theme }) => theme.colors.charcoal};
  }

  &:last-child {
    margin-bottom: 0rem;
  }

  [class^='Field'] {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  [class^='Field-sc'] [class^='Label-sc'],
  [class^='Field-sc'] [class^='Input-sc'] {
    position: relative;
    transform: none;
    margin: 0;
    padding: 0;
    ${({ theme }) => theme.fonts.weights.weak};
  }
  [class^='Field-sc'] svg {
    display: none;
  }
  [class^='DateTimeField__DisplayTime-sc'] {
    align-self: normal;
    vertical-align: initial;
    display: initial;
    font-weight: 400;

    ${({ overdue, theme }) => overdue && `color: ${theme.colors.rose}`}
  }
`;

export const ChecklistText = styled.span`
  display: inline-block;
  color: ${(props) =>
    props.isRequired ? props.theme.colors.rose : props.theme.colors.charcoal};
  padding-top: 3px;
`;

export const WeekDayContainer = styled.div`
  padding: 0.2rem 0.8rem;
  background: rgba(241, 241, 241, 0.5);
  border-radius: 0.4rem;
  display: inline-flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  margin-left: 3.3rem;
  margin-bottom: ${(props) => (props.hasAdjacentFields ? '1.8rem' : '0')};
  span {
    ${({ theme }) => theme.fonts.weights.weak};
    ${({ theme }) => theme.fonts.sizes.smallest};
    color: ${({ theme }) => theme.colors.charcoal04};
  }
`;
