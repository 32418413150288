import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Session,
  EmploymentContext,
  initGQLData as initData,
  getPageInfo,
  PagableList,
  EmptyCard,
} from '@optii/shared';
import {
  useChecklistTemplatesLazyQuery,
  ChecklistTemplatesDocument,
} from '@optii/global';
import { useDeleteChecklistTemplateMutation } from '../../api/checklists';
import Fields from './fields';
import Headers from './headers';
import { ChecklistModalContext } from '../contexts';

const ListContainer = styled.div`
  padding-left: 1.2rem;
  padding-right: 2rem;
`;

function ChecklistTable({
  listBeginRef,
  itemIdToScrollTo,
  colSort,
  checklistTemplatesData,
  onDeleteChecklistTemplate,
  editChecklistTemplate,
  copyChecklistTemplate,
  fulfillmentTemplate,
  viewChecklistTemplate,
  onDeleteChecklistTemplateLoading,
}: {
  listBeginRef: any;
  itemIdToScrollTo: unknown;
  colSort: any;
  checklistTemplatesData: any;
  onDeleteChecklistTemplate: any;
  editChecklistTemplate: any;
  copyChecklistTemplate: any;
  fulfillmentTemplate: any;
  viewChecklistTemplate: any;
  onDeleteChecklistTemplateLoading: any;
}) {
  return (
    <Table data-testid="checklistTemplatesListTable">
      <Table.Head modifiers={['noRightMargin']}>
        <Headers onSort={colSort} />
      </Table.Head>
      {checklistTemplatesData?.map((template: any) => (
        <Table.RowWrap
          ref={itemIdToScrollTo === template.id ? listBeginRef : null}
          key={template.id}
          data-testid="checklist-template-row"
          modifiers={['lightFontWeight', 'inlineNoWrap', 'preserveBorder']}
        >
          <Fields
            checklistTemplate={template}
            onDeleteChecklistTemplate={onDeleteChecklistTemplate}
            onEditChecklistTemplate={editChecklistTemplate}
            onCopyChecklistTemplate={copyChecklistTemplate}
            onFulfillmentTemplate={fulfillmentTemplate}
            onViewChecklistTemplate={viewChecklistTemplate}
            onDeleteChecklistTemplateLoading={onDeleteChecklistTemplateLoading}
          />
        </Table.RowWrap>
      ))}
    </Table>
  );
}

export default function ChecklistList() {
  const { t } = useTranslation(['common', 'checklists']);
  const { employee } = useContext(EmploymentContext.Context);
  const { query, setQuery, setData } = useContext(ChecklistModalContext);
  const { globalSnack } = useContext(Session);

  const [checklistTemplatesData, setChecklistTemplatesData] = useState([]);

  const [pageInfo, setPageInfo] = useState();

  const variables = {
    first: 50,
    orderBy: 'name_ASC', // "name"
  };

  const [
    getChecklistTemplates,
    {
      data: checklistTemplates,
      fetchMore: fetchMoreChecklistTemplates,
      loading: checklistTemplatesLoading,
    },
  ] = useChecklistTemplatesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network', // works for Departments, Users, and brokes (if we want to delete one and alone item on the page) JobItems, Floorplan -  does not update cache on refetch (https://github.com/apollographql/apollo-client/issues/6804). Removing this policy brokes pagination. Temporary solution is to use not refetch but get api call function.
    nextFetchPolicy: 'cache-first',
    variables,
    context: { _instance: 'node' },
  });

  const [
    deleteChecklistTemplate,
    { loading: onDeleteChecklistTemplateLoading },
  ] = useDeleteChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  useEffect(() => {
    if (checklistTemplates) {
      setChecklistTemplatesData(initData(checklistTemplates));
      setPageInfo(getPageInfo(checklistTemplates));
    }
  }, [checklistTemplates]);

  const refresh = async (q: { refresh: string }) => {
    if (q.refresh === 'true') {
      await getChecklistTemplates();
      setQuery({ refresh: 'false' });
    }
  };

  useEffect(() => {
    getChecklistTemplates();
  }, []);

  useEffect(() => {
    refresh(query as any);
  }, [query.refresh]);

  const colSort = (col: any) => {
    let sort = col.id;
    if (col.isSortDesc) {
      sort = `${sort}_DESC`;
    } else {
      sort = `${sort}_ASC`;
    }
    const payloadVariables = {
      ...variables,
      orderBy: `${sort}`,
    };

    getChecklistTemplates({
      variables: {
        ...payloadVariables,
      },
    });
  };

  const viewChecklistTemplate = (template: any) => {
    setData(template);
    setQuery({ readOnlyChecklist: 'true', openChecklistTemplate: template.id });
  };
  const editChecklistTemplate = (template: any) => {
    setQuery({ edit: true, openChecklistTemplate: template.id });
  };
  const copyChecklistTemplate = (template: any) => {
    setQuery({ copy: true, openChecklistTemplate: template.id });
  };
  const fulfillmentTemplate = (template: any) => {
    setData(template);
    setQuery({
      fulfillment: template.id,
    });
  };

  const onDeleteChecklistTemplate = async (template: any) => {
    try {
      await deleteChecklistTemplate({
        variables: {
          id: template.id,
        },
        update(cache, { data }) {
          const deletedTemplateId = template.id;
          const templates = cache.readQuery({
            query: ChecklistTemplatesDocument,
          }) as any;

          const updatedChecklistTemplates =
            checklistTemplates?.page?.edges?.filter(
              (item) => item.node.value !== deletedTemplateId,
            );

          if (templates && deletedTemplateId) {
            cache.writeQuery({
              query: ChecklistTemplatesDocument,
              data: {
                page: {
                  edges: updatedChecklistTemplates,
                  pageInfo: {
                    ...templates.page?.pageInfo,
                  },
                  totalCount: templates.page.totalCount - 1,
                },
              },
            });
          }
        },
      });

      globalSnack({
        message: t(
          'checklists:The checklist template {{displayName}} has been deleted',
          {
            displayName: template.name,
            timeout: 5000,
          },
        ),
      });
    } catch (error) {
      console.error(error);
      globalSnack({
        message: t(
          'checklists:Something went wrong while attempting to delete this checklist template.',
        ),
        timeout: 5000,
        error: true,
      });
    }
  };

  return (
    <div data-testid="checklist-template-list">
      {checklistTemplatesData?.length > 0 ? (
        <ListContainer data-testid="checklist-template-list-items">
          <PagableList
            data={checklistTemplatesData}
            pageInfo={pageInfo}
            fetchMore={fetchMoreChecklistTemplates}
            withCount
            modifiers={[]}
            useSecondaryButton
            loading={checklistTemplatesLoading}
            list={(listBeginRef: any, itemIdToScrollTo: any) =>
              ChecklistTable({
                listBeginRef,
                itemIdToScrollTo,
                colSort,
                checklistTemplatesData,
                onDeleteChecklistTemplate,
                editChecklistTemplate,
                copyChecklistTemplate,
                fulfillmentTemplate,
                viewChecklistTemplate,
                onDeleteChecklistTemplateLoading,
              })
            }
          />
        </ListContainer>
      ) : (
        !checklistTemplatesLoading && (
          <EmptyCard message={t('checklists:You don’t have any checklists')} />
        )
      )}
    </div>
  );
}
