import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam, BooleanParam } from 'use-query-params';
import { GoogleAnalyticsClient, GA_EVENTS, PrimaryButton } from '@optii/shared';
import { ChecklistModalContext } from './contexts';
import Details from './details/index';
import List from './list';
import SettingsMain from './blocks/settingsMain';

const AddChecklist = styled(PrimaryButton)`
  margin-left: 1.3rem;
`;

export default function Checklists({
  modalMode,
  setOpenModal,
  add,
}: {
  modalMode: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  add?: boolean;
}) {
  const { t } = useTranslation(['checklists']);
  const [isAdd, setIsAdd] = useState(add || false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
    copy: BooleanParam,
    openJob: StringParam,
    openChecklistTemplate: StringParam,
    fulfillment: StringParam,
    readOnlyChecklist: StringParam,
    refresh: StringParam,
  });

  const closeChecklist = useCallback(({ refresh }: { refresh?: string }) => {
    if (typeof setOpenModal === 'function') setOpenModal(false);
    setQuery({
      openChecklistTemplate: undefined,
      edit: undefined,
      copy: undefined,
      fulfillment: undefined,
      readOnlyChecklist: undefined,
      refresh,
    });
    setIsAdd(false);
    setData(null);
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [isChildOpen, setChildOpen] = useState(false);
  const isEdit = !!query.edit;
  const isCopy = !!query.copy;
  const isFulfillment = query.fulfillment && query.fulfillment !== '';
  const isReadOnly = query.readOnlyChecklist && query.readOnlyChecklist !== '';
  const readOnly = !isEdit && !isAdd && !isFulfillment && !isCopy;

  const openModal =
    query.openChecklistTemplate ||
    isAdd ||
    isEdit ||
    isFulfillment ||
    isCopy ||
    isReadOnly;

  const checklistContextValue = useMemo(
    () => ({
      close: closeChecklist,
      open: setOpen,
      openChild: setChildOpen,
      isOpen,
      isChildOpen,
      readOnly,
      isReadOnly,
      isAdd,
      isEdit,
      isCopy,
      isFulfillment,
      loading,
      setLoading,
      data,
      setData,
      query,
      setQuery,
      modalMode,
    }),
    [
      closeChecklist,
      setOpen,
      setChildOpen,
      isChildOpen,
      isOpen,
      readOnly,
      isReadOnly,
      isAdd,
      isEdit,
      isCopy,
      isFulfillment,
      loading,
      setLoading,
      data,
      setData,
      query,
      setQuery,
      modalMode,
    ],
  );

  return (
    <ChecklistModalContext.Provider value={checklistContextValue}>
      {modalMode ? (
        openModal && <Details />
      ) : (
        <SettingsMain {...{ modifiers: 'withMoreThanThreeTableColumns' }}>
          <SettingsMain.Header>
            <SettingsMain.Title>
              {t('checklists:Checklists')}
            </SettingsMain.Title>
            <AddChecklist
              onClick={() => {
                GoogleAnalyticsClient.event(GA_EVENTS.addCheckList);
                setIsAdd(true);
                setOpen(true);
              }}
              data-testid="add-checklist-template-btn"
            >
              {`${t('common:Add')} ${t('checklists:Checklist')}`}
            </AddChecklist>
          </SettingsMain.Header>
          <List />
          <Details />
        </SettingsMain>
      )}
    </ChecklistModalContext.Provider>
  );
}
