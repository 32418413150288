import React, { CSSProperties, useEffect, useState } from 'react';
import { GET_EMPLOYEE_LIST, GET_JOB_BY_ID, initGQLData } from '@optii/shared';
import {
  Modal,
  FONTS,
  Flex,
  Typography,
  ConfigProvider,
  COLORS,
  Button,
  Descriptions,
  DescriptionsProps,
  Skeleton,
  SPACING,
  Divider,
  Form,
  useForm,
  FormItem,
  Select,
  TextArea,
  Checkbox,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import { GetJobByIdQuery } from '@optii/jobs/api/jobs';

type HandleCompleteFailJobParams = {
  notes: string;
  skipCheck: boolean;
  assigneeId: number;
};

interface FailConfirmationModalProps {
  onClose: () => void;
  handleCompleteFailJob: ({
    notes,
    skipCheck,
    assigneeId,
  }: HandleCompleteFailJobParams) => Promise<void>;
  getFailedTasksQuantity: (id?: string) => Promise<number>;
  job: GetJobByIdQuery['job'];
}

const DESCRIPTION_LABEL_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  fontSize: FONTS.xSmall.size,
  letterSpacing: FONTS.xSmall.letterSpacing,
  fontWeight: 500,
  alignItems: 'center',
};

const DESCRIPTION_CONTENT_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  fontSize: FONTS.medium.size,
  letterSpacing: FONTS.medium.letterSpacing,
  fontWeight: 500,
};

export const AddCorrectionModal: React.FC<FailConfirmationModalProps> = ({
  onClose,
  handleCompleteFailJob,
  getFailedTasksQuantity,
  job,
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['jobs', 'common']);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);

  const { data: employeeListData, loading: employeeListLoading } = useQuery(
    GET_EMPLOYEE_LIST,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: (error) => {
        console.error(error);
      },
      variables: {
        status: 'active',
      },
    },
  );

  useQuery(GET_JOB_BY_ID, {
    variables: {
      id: job.parentJobId,
    },
    onCompleted({ job: parentJob }) {
      if (parentJob.assignee) {
        form.setFieldValue('assigneeId', parentJob.assignee.id);
      }
    },
    skip: !job.parentJobId,
  });

  const employeeOptions = initGQLData(employeeListData).map(
    ({
      id,
      userFirstName,
      userLastName,
    }: {
      id: string;
      userFirstName: string;
      userLastName: string;
    }) => ({
      value: id,
      label: `${userFirstName} ${userLastName}`,
    }),
  );

  const formattedLocations = job.locations
    .map((location) => location?.shortDisplayName)
    .join(', ');

  useEffect(() => {
    getFailedTasksQuantity(job.id).then((taskQuantity) => {
      setQuantity(taskQuantity);
    });
  }, [getFailedTasksQuantity, setQuantity, job.id]);

  const onFinish = async (values: {
    notes: string;
    skipCheck: boolean;
    assigneeId: number;
  }) => {
    const { skipCheck, notes, assigneeId } = values;

    setLoading(true);
    await handleCompleteFailJob({
      skipCheck,
      notes,
      assigneeId,
    })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const items: DescriptionsProps['items'] = [
    {
      key: 'failedTasks',
      label: t('jobs:Failed Tasks'),
      children: (
        <Skeleton
          title={false}
          paragraph={{
            rows: 1,
            width: 70,
          }}
          loading={!quantity}
        >
          {quantity ?? 0}
        </Skeleton>
      ),
    },
    {
      key: 'location',
      label: t('jobs:Location'),
      children: formattedLocations,
      labelStyle: {
        marginLeft: 20,
      },
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Divider: {
            marginLG: SPACING.SIZE_SM,
          },
        },
      }}
    >
      <Modal
        title={
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Montserrat',
                fontSize: FONTS.h3.size,
                colorText: COLORS.neutral[8],
              },
            }}
          >
            <Flex justify="space-between" align="center">
              <Typography.Text
                strong={false}
                style={{
                  letterSpacing: FONTS.h3.letterSpacing,
                  fontWeight: 500,
                }}
              >
                {t('jobs:Add Correction')}
              </Typography.Text>

              <Button
                shape="circle"
                icon={<CloseOutlined />}
                type="text"
                onClick={onClose}
              />
            </Flex>
          </ConfigProvider>
        }
        closeIcon={null}
        onClose={onClose}
        open
        cancelText={t('common:Cancel')}
        okText={t('common:Save')}
        okButtonProps={{
          disabled: loading,
        }}
        onOk={() => form.submit()}
        onCancel={onClose}
      >
        <Descriptions
          items={items}
          colon={false}
          column={1}
          labelStyle={DESCRIPTION_LABEL_STYLE}
          contentStyle={DESCRIPTION_CONTENT_STYLE}
        />
        <Divider />

        <Form
          form={form}
          autoComplete="off"
          layout="horizontal"
          colon={false}
          onFinish={onFinish}
        >
          <FormItem
            label={t('jobs:Assign To')}
            name="assigneeId"
            rules={[
              {
                required: true,
                message: t('common:Assignee must be specified.'),
              },
            ]}
          >
            <Select
              options={employeeOptions}
              optionFilterProp="label"
              loading={employeeListLoading}
              allowClear={false}
            />
          </FormItem>

          <FormItem label="Add Note" name="notes">
            <TextArea />
          </FormItem>

          <FormItem
            style={{
              marginLeft: 64,
            }}
            name="skipCheck"
            valuePropName="checked"
          >
            <Checkbox>{t('jobs:Skip Check')}</Checkbox>
          </FormItem>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};
