import styled from 'styled-components/macro';

import { isPlatform } from '@ionic/react';
// When we are in the iOS app, we often need to modify space
// Mostly we use this for the top of the app where the status bar is
function capacitorSpaceModifier(previous: string, offset = '2.5rem') {
  return isPlatform('capacitor') ? `calc(${previous} + ${offset})` : previous;
}

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: ${capacitorSpaceModifier('0rem', '3rem')};
`;

export default Top;
