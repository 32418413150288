import { useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Icons, ToggleContent, Table, Confirmation } from '@optii/shared';
import moment from 'moment';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { ChecklistModalContext } from '../contexts';

const FieldContainer = styled.div`
  display: flex;
`;

const IconsFieldContainer = styled(FieldContainer as 'div')`
  justify-content: end;
`;

const Fields = styled.div`
  margin: 0;
  height: 2rem;
  min-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Column = styled(Table.Col as 'div')<{ modifiers: string[] }>`
  height: 2rem;
  min-height: 2rem;
  .tableField {
    padding-inline-start: 0;
  }
`;

const NameField = styled.div`
  color: ${({ theme }) => theme.colors.moderateCyan};
  ${({ theme }) => theme.fonts.weights.medium};
  cursor: pointer;
`;

const NameColumn = styled(Column as 'div')<{ modifiers: string[] }>`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 38rem;
  }
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

function toggleDeleteChecklistTemplate(
  show: () => void,
  t: TFunction<['common'], undefined>,
) {
  return (
    <Icons.Delete
      data-testid="removeChecklistTemplate"
      title={t('common:Delete')}
      onClick={() => {
        GoogleAnalyticsClient.event(GA_EVENTS.deleteChecklist);
        show();
      }}
    />
  );
}

function onDeleteTemplate(
  hide: () => void,
  checklistTemplate: { name: string },
  onDeleteChecklistTemplate: (checklistTemplate: any) => void,
  onDeleteChecklistTemplateLoading: boolean,
  t: TFunction<['common'], undefined>,
) {
  return (
    <Confirmation
      data-testid="remove-confirmation"
      title={t('common:Delete {{checklistTemplateName}}', {
        checklistTemplateName: checklistTemplate.name,
      })}
      hide={hide}
      action={t('common:Yes, Delete')}
      loading={onDeleteChecklistTemplateLoading}
      confirm={() => {
        GoogleAnalyticsClient.event(GA_EVENTS.confirmDeleteChecklist);
        onDeleteChecklistTemplate(checklistTemplate);
      }}
      modifiers={['fullWidthMobile']}
    >
      <p>
        {t(
          'checklists:Are you sure you want to delete this checklist template?',
          {
            checklistTemplateName: checklistTemplate.name,
          },
        )}
      </p>
    </Confirmation>
  );
}

export default function ChecklistFields({
  checklistTemplate,
  onDeleteChecklistTemplate,
  onDeleteChecklistTemplateLoading,
  onEditChecklistTemplate,
  onCopyChecklistTemplate,
  onViewChecklistTemplate,
  onFulfillmentTemplate,
}: {
  checklistTemplate: any;
  onDeleteChecklistTemplate: () => void;
  onDeleteChecklistTemplateLoading: boolean;
  onEditChecklistTemplate: (checklist: any) => void;
  onCopyChecklistTemplate: (checklist: any) => void;
  onViewChecklistTemplate: (checklist: any) => void;
  onFulfillmentTemplate: () => void;
}) {
  const { t } = useTranslation(['common', 'checklists']);
  const { open } = useContext(ChecklistModalContext);

  function handleClick(mode: 'edit' | 'copy', checklist: any) {
    switch (mode) {
      case 'edit':
        GoogleAnalyticsClient.event(GA_EVENTS.editChecklist);
        onEditChecklistTemplate(checklist);
        break;

      case 'copy':
        GoogleAnalyticsClient.event(GA_EVENTS.checklistCopyListView);
        onCopyChecklistTemplate(checklist);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <NameColumn
        key="displayName"
        modifiers={['noRightMargin', 'hiddenOverflow', 'longDynamic']}
      >
        <FieldContainer>
          <NameField
            onClick={() => {
              GoogleAnalyticsClient.event(GA_EVENTS.viewChecklist);
              onViewChecklistTemplate(checklistTemplate);
            }}
            data-testid="display-name"
          >
            {checklistTemplate.name}
          </NameField>
        </FieldContainer>
      </NameColumn>
      <Column key="items" modifiers={['hideAtSmBreak', 'noRightMargin']}>
        <FieldContainer>
          <Fields data-testid="items">{checklistTemplate.tasks?.length}</Fields>
        </FieldContainer>
      </Column>
      <Column
        key="lastUpdated"
        modifiers={['hideAtSmBreak', 'noRightMargin', 'shortDynamic']}
      >
        <FieldContainer>
          <Fields data-testid="last-updated">
            {checklistTemplate.updatedAt
              ? moment(checklistTemplate.updatedAt).format('MMMM D, YYYY')
              : moment(checklistTemplate.createdAt).format('MMMM D, YYYY')}
          </Fields>
        </FieldContainer>
      </Column>
      <Column key="icons" modifiers={['endActions']}>
        <IconsFieldContainer>
          <Fields data-testid="icons">
            <Icons modifiers={['alignCenter']}>
              <Icons.Edit
                data-testid="toggleEdit"
                title={t('common:Edit')}
                onClick={() => {
                  handleClick('edit', checklistTemplate);
                }}
              />
              <Icons.Copy
                data-testid="toggleCopy"
                title={t('common:Copy')}
                onClick={() => {
                  handleClick('copy', checklistTemplate);
                }}
              />
              <ToggleContent
                shown
                toggle={(show: () => void) => {
                  toggleDeleteChecklistTemplate(show, t);
                }}
                content={(hide: () => void) => {
                  onDeleteTemplate(
                    hide,
                    checklistTemplate,
                    onDeleteChecklistTemplate,
                    onDeleteChecklistTemplateLoading,
                    t,
                  );
                }}
              />
            </Icons>
          </Fields>
        </IconsFieldContainer>
      </Column>
    </>
  );
}
