import React, { useCallback, useContext, useRef, useMemo } from 'react';
import { useFormik, FormikContext } from 'formik';
import styled from 'styled-components/macro';
import _ from 'lodash';

import {
  FormikField,
  Session,
  EmploymentContext,
  GA_EVENTS,
  FormBlock,
  GoogleAnalyticsClient,
  escapeCommas,
  ButtonPrimary as SubmitButton,
  ButtonQuarternary as CancelButton,
} from '@optii/shared';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';
import {
  useChecklistTemplateNameCollisionLazyQuery,
  useCopyChecklistTemplateMutation,
  useCreateChecklistTemplateMutation,
  useUpdateChecklistTemplateMutation,
} from '../../../api/checklists';
import CheckListTemplate from './CheckListTemplate';
import { CHECKLIST_TYPES } from '../../taskConstants';
import CheckList from '../..';
import { CHECKLIST_TASK_TYPE_CONFIG } from '../../constants';
import { ChecklistScore } from './ChecklistScore';

const CheckListFormFooter = styled(FormBlock.Footer as 'div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 1.6rem 1.5rem 2rem 1.5rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  button {
    margin-left: 2rem;
  }
`;

type Props = {
  isEdit?: boolean;
  isAdd?: boolean;
  isCopy?: boolean;
  readOnly?: boolean;
  data: any;
  onFinish: (v: any) => void;
};

export default function ChecklistTemplateForm({
  isEdit,
  isAdd,
  isCopy,
  readOnly,
  data,
  onFinish,
}: Props) {
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['common', 'checklist']);
  const { employee } = useContext(EmploymentContext.Context);
  const isDuplicateRef = useRef(false);

  const [checkForDuplicateName, { loading: checkForDuplicateNameLoading }] =
    useChecklistTemplateNameCollisionLazyQuery({
      context: { _instance: 'node' },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  const [
    createChecklistTemplate,
    { loading: onCreateChecklistTemplateLoading },
  ] = useCreateChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  const [
    updateChecklistTemplate,
    { loading: onUpdateChecklistTemplateLoading },
  ] = useUpdateChecklistTemplateMutation({
    context: { _instance: 'node' },
  });

  const [copyChecklistTemplate, { loading: onCopyChecklistTemplateLoading }] =
    useCopyChecklistTemplateMutation({
      context: { _instance: 'node' },
    });

  const validateForm = async (values: { displayName?: string }) => {
    const errors = {
      displayName: '',
    };
    if (!values?.displayName?.trim()) {
      errors.displayName = t('checklist:Checklist name is a required field.');
    } else if (isDuplicateRef.current) {
      errors.displayName = t(
        'checklist:A checklist with this name already exists.',
      );
    }
    return errors;
  };

  const initValues = () => {
    if (data) {
      return {
        displayName: data.name,
        description: data.description || '',
        checkList: data.tasks,
        enableScoring: data.enableScoring || false,
      };
    }

    return {
      displayName: '',
      description: '',
      enableScoring: false,
    };
  };

  const formik = useFormik<{
    displayName: string;
    description?: string;
    checkList?: any;
    enableScoring?: boolean;
  }>({
    validate: validateForm,
    initialValues: initValues(),
    onSubmit: async () => {
      console.info('Submitting...');
    },
  });

  const validateChecklistName = useCallback(
    async (values: any, currentName: string, isEditMode: boolean) => {
      if (isEditMode && currentName === escapeCommas(values.displayName))
        return { isValid: true };

      const name = escapeCommas(values.displayName) as string;

      const { data: checkForDupName } = await checkForDuplicateName({
        variables: {
          name,
        },
      });
      if (checkForDupName?.nameExists) {
        isDuplicateRef.current = true;
        formik.validateForm();
        return {
          isValid: false,
        };
      }
      return {
        isValid: true,
      };
    },
    [checkForDuplicateName, formik],
  );

  const formatTasksForSubmission = (
    checkList: any[],
    checklistTemplateId?: string,
  ) => {
    let tasks = checkList?.filter((item) => !!item.label);
    let parentTaskId: string | null = null;

    tasks = tasks?.map((task, index) => {
      const {
        attachment,
        id,
        label,
        note,
        taskType,
        required = false,
        defaultValue,
      } = task;
      const isGroupHeader = taskType === CHECKLIST_TYPES.groupHeader;

      if (isGroupHeader) {
        parentTaskId = id;
      }

      let fulfillmentDefaultValues = {};
      if (
        typeof CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment
          ?.getDefaultValue === 'function'
      ) {
        fulfillmentDefaultValues =
          CHECKLIST_TASK_TYPE_CONFIG[taskType]?.fulfillment?.getDefaultValue(
            task,
          );
      }

      const newItem = {
        attachment: attachment ?? [],
        id: isCopy ? uuidv4() : id,
        checklistTemplateId,
        taskType,
        notes: note || '',
        label,
        parent_task_id: isGroupHeader ? null : parentTaskId,
        ordering_value: String(index),
        required,
        defaultValue,
        ...fulfillmentDefaultValues,
      };

      return newItem;
    });

    return tasks;
  };

  async function handleAddSubmit(values: any) {
    const checklistTemplateId = uuidv4();
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;
    const createChecklistTemplateInput = {
      id: checklistTemplateId,
      name: values.displayName,
      description: values.description,
      creator: {
        employeeId: parseInt(employeeId, 10),
      },
      tasks: formatTasksForSubmission(values.checkList, checklistTemplateId),
    };

    try {
      await createChecklistTemplate({
        variables: {
          input: createChecklistTemplateInput,
        },
        context: { _instance: 'node' },
      });

      globalSnack({
        message: t('checklists:{{name}} was successfully added', {
          name: values.displayName,
        }),
      });
      if (typeof onFinish === 'function') onFinish({ refresh: true });
    } catch (e) {
      console.error('Error creating checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to create checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function handleUpdateSubmit(values: any) {
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;

    const updateChecklistTemplateInput = {
      id: data.id,
      createdAt: data.createdAt,
      name: values.displayName,
      description: values.description,
      creator: {
        id: parseInt(data.creator?.id, 10),
        employeeId: parseInt(data.employee?.id ?? employeeId, 10),
        createdAt: data.creator?.createdAt,
        firstName: data.creator?.firstName,
        lastName: data.creator?.lastName,
      },
      tasks: formatTasksForSubmission(values.checkList, data.id),
    };

    try {
      await updateChecklistTemplate({
        variables: {
          id: data.id,
          input: updateChecklistTemplateInput,
        },
        context: { _instance: 'node' },
      });

      globalSnack({
        message: t('checklists:{{name}} was successfully updated', {
          name: values.displayName,
        }),
      });

      if (typeof onFinish === 'function') onFinish({ refresh: true });
    } catch (e) {
      console.error('Error updating checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to update checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function handleCopySubmit(values: any) {
    const employeeId = typeof employee === 'boolean' ? '' : employee.id;
    const checklistTemplateId = uuidv4();
    const copyChecklistTemplateInput = {
      id: checklistTemplateId,
      name: values.displayName,
      description: values.description,
      creator: {
        employeeId: parseInt(employeeId, 10),
      },
      tasks: formatTasksForSubmission(values.checkList, checklistTemplateId),
    };

    try {
      await copyChecklistTemplate({
        variables: {
          id: data.id,
          copyChecklistTemplateInput,
        },
        context: { _instance: 'node' },
      });

      globalSnack({
        message: t('checklists:{{name}} was successfully copied', {
          name: copyChecklistTemplateInput.name,
        }),
      });

      if (typeof onFinish === 'function') onFinish({ refresh: true });
    } catch (e) {
      console.error('Error copying checklist template', e);

      globalSnack({
        message: t(
          'checklists:Something went wrong attempting to copy checklist',
        ),
        error: true,
        timeout: 5000,
      });
    }
  }

  async function onFormSubmit(values: any) {
    const { description } = values;
    const { isValid } = await validateChecklistName(
      values,
      data?.name,
      isEdit || isCopy || false,
    );

    if (description !== '')
      GoogleAnalyticsClient.event(GA_EVENTS.checklistWithDescription);
    if (!isValid) return;

    if (isCopy) handleCopySubmit(values);
    else if (isEdit) handleUpdateSubmit(values);
    else handleAddSubmit(values);
  }

  const actionText = (isEditChecklist?: boolean) => {
    if (isCopy) {
      if (onCopyChecklistTemplateLoading) {
        return t('common:Copying...');
      }
      return t('common:Copy');
    }
    if (isEditChecklist) {
      if (onUpdateChecklistTemplateLoading) {
        return t('common:Updating...');
      }
      return t('common:Update');
    }
    if (onCreateChecklistTemplateLoading) {
      return t('common:Saving...');
    }
    return t('common:Save');
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    setErrors,
  } = formik;

  const handleChecklistChange = ({
    data: checklistData,
    isValid,
  }: {
    data: any;
    isValid: boolean;
  }) => {
    setFieldValue('checkList', checklistData, false);

    if (!isValid) {
      setFieldError('checkList', 'checklist error');
    } else {
      const { checkList, ...newErros } = errors;
      setErrors(newErros);
    }
  };

  const canSave = useMemo(
    () =>
      Object.keys(errors)?.filter((key) => errors[key as keyof typeof errors])
        .length === 0 &&
      values.displayName?.trim() !== null &&
      formatTasksForSubmission(values.checkList)?.length > 0,
    [errors, values.displayName, values.checkList],
  );

  const canUpdate = useMemo(() => {
    if (!canSave) return false;
    const initialValues = initValues();
    initialValues.checkList = formatTasksForSubmission(initialValues.checkList);
    const currentValues = {
      displayName: values.displayName?.trim(),
      description: values.description,
      checkList: formatTasksForSubmission(values.checkList),
    };
    const hasUpdates = !_.isEqual(initialValues, currentValues);
    return hasUpdates;
  }, [canSave, values]);

  const checkAndSubmit = async () => {
    if (isCopy) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistCopy);
    } else if (isEdit) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistUpdate);
    } else if (canSave) {
      GoogleAnalyticsClient.event(GA_EVENTS.checklistSave);
    }
    await onFormSubmit(values);
  };

  const loading =
    onCreateChecklistTemplateLoading ||
    onUpdateChecklistTemplateLoading ||
    checkForDuplicateNameLoading;

  const providerValue = useMemo(() => ({ ...formik }), [formik]);

  return (
    <FormikContext.Provider value={providerValue}>
      <CheckList.TopModalContent>
        {isEdit && (
          <CheckList.TemplateMessage data-testid="checklist-template-edit-message">
            {t('checklist:Checklist updates only apply to:')}
            <ul>
              <li>{t('checklist:Unassigned project jobs')}</li>
              <li>{t('checklist:Repeating jobs')}</li>
            </ul>
          </CheckList.TemplateMessage>
        )}
        <FormikField
          styles={{ marginBottom: '1.5rem' }}
          name="displayName"
          label={t('checklist:Checklist Name')}
          type="formikText"
          placeholder={t('checklist:Enter Checklist Name')}
          required
          errors={errors}
          value={values.displayName}
          onChange={(e: any) => {
            if (isDuplicateRef.current) {
              isDuplicateRef.current = false;
              formik.validateForm();
            }

            setFieldValue('displayName', e.target.value, false);
          }}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
        />
        <FormikField
          name="description"
          label={t('checklist:Description')}
          type="text"
          placeholder=""
          required={false}
          errors={errors}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
        />

        <ChecklistScore setFieldValue={setFieldValue} values={values} />
      </CheckList.TopModalContent>
      <CheckListTemplate
        isEdit={isEdit || isCopy}
        isAdd={isAdd}
        isReadOnly={readOnly}
        onChange={handleChecklistChange}
        data={values.checkList}
        isScoringEnabled={values.enableScoring}
        footer={
          <CheckListFormFooter>
            <CancelButton onClick={onFinish} type="button">
              {t('common:Cancel')}
            </CancelButton>
            <SubmitButton
              data-testid="Save"
              type="submit"
              disabled={!canUpdate || !canSave || loading}
              onClick={checkAndSubmit}
            >
              {actionText(isEdit)}
            </SubmitButton>
          </CheckListFormFooter>
        }
      />
    </FormikContext.Provider>
  );
}
