import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';

import { SPACING } from '@optii-solutions/ui-library';
import { CHECKLIST_TYPES, CHECKLIST_TASK_FIELDS } from '../../taskConstants.ts';
import { Container } from '../../elements.tsx';
import Task from '../../blocks/Task.tsx';
import { CHECKLIST_TASK_TYPE_CONFIG } from '../../constants.ts';

function CheckListFulfillment({
  data = [],
  submitted = false,
  onChange = () => {
    // onChange function
  },
  onTaskChange = () => {
    // onTaskChange function
  },
  onConvertTask,
  readOnly,
}) {
  const [close, setClose] = useState({});

  function groupItems(items) {
    if (!items || items.length === 0) return items;

    const groupIdList = [];
    let groupId = 0;

    const newItems = items.map((item, index) => {
      const newItem = { ...item };

      if (newItem.taskType === CHECKLIST_TYPES.groupHeader) {
        groupId += 1;
        groupIdList.push(groupId);
      }

      newItem.groupId = groupId;

      return newItem;
    });

    return newItems;
  }
  const checkListTasks = useMemo(() => groupItems(data), [data]);

  function validation() {
    let hasError = false;

    hasError = checkListTasks.some((task) => {
      const { required = false, taskType } = task;
      const { fulfillment } = CHECKLIST_TASK_TYPE_CONFIG[taskType];
      const { fulfillable, isFulfilled } = fulfillment;
      return required && fulfillable ? !isFulfilled(task) : false;
    });

    return !hasError;
  }

  useEffect(() => {
    onChange({
      data: checkListTasks,
      isValid: validation(),
    });
  }, [data]);

  const handleTaskChange =
    ({ orderingValue, id, createdAt, templateTaskId }) =>
    (incomingValues) => {
      onTaskChange({
        ...incomingValues,
        orderingValue,
        id,
        createdAt,
        templateTaskId,
      });
    };

  const handleToggleOpen = (groupId) => {
    const newClose = { ...close };
    const isClosed = !!close[groupId];

    if (isClosed) {
      delete newClose[groupId];
    } else {
      newClose[groupId] = true;
    }

    setClose(newClose);
  };

  function isSubTask(task) {
    return task.groupId > 0 && task.taskType !== CHECKLIST_TYPES.groupHeader;
  }

  const getFulfillmentValues = (task) =>
    CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.getValue(task);

  return (
    <Container
      style={{ padding: SPACING.NONE }}
      data-testid="checklist-fulfillment"
    >
      {checkListTasks?.map(
        (task, index) =>
          (!close[task.groupId] ||
            task.taskType === CHECKLIST_TYPES.groupHeader) && (
            <Task
              style={{ padding: SPACING.NONE }}
              taskType={task.taskType}
              value={task.value}
              label={task.label}
              note={task.notes}
              attachment={task.attachments || task.attachment}
              onConvertTask={onConvertTask}
              childJobsId={task.childJobsIds}
              key={task.id}
              groupId={task.groupId}
              id={task.id}
              required={task.required}
              onFulfillmentChange={handleTaskChange({
                ordering_value: task.orderingValue || String(index),
                orderingValue: task.orderingValue || String(index),
                id: task.id,
                createdAt: task.createdAt,
                templateTaskId: task.templateTaskId,
              })}
              subTask={isSubTask(task)}
              isReadOnly={readOnly}
              onToggleOpen={handleToggleOpen}
              isOpen={!close[task.groupId]}
              createdAt={task.createdAt}
              submitted={submitted}
              {...getFulfillmentValues(task)}
              fulfillmentAttachment={
                task[CHECKLIST_TASK_FIELDS.fulfillmentAttachment]
              }
              fulfillmentNote={task.fulfillmentNote}
              isFulfillment
            />
          ),
      )}
    </Container>
  );
}

export default CheckListFulfillment;
