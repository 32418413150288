import React, { useContext, useEffect, ReactNode } from 'react';
import { Drawer, SPACING } from '@optii/ui-library';
import { Loading, Icons } from '@optii/shared';

import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam, BooleanParam } from 'use-query-params';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { useChecklistTemplateQuery } from '@optii/global';
import ModalElements from '../blocks/ModalElements';

import { ChecklistModalContext } from '../contexts';

const MODIFIER_CONFIG = {
  childrenPadding: () => `
    padding: 1.6rem 1.6rem 0 1.6rem;
    border-bottom: 1px solid var(--components-form-border, #D9D9D9);
  `,
};

const ModalTop = styled(ModalElements.Top as 'div')<{ modifiers: any }>`
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export default function ChecklistModal({
  modalName,
  children,
  modalWidth,
  modifiers = ['right'],
}: {
  modalName: string;
  children: ReactNode;
  modalWidth: number;
  modifiers: string[];
}) {
  const [query, setQuery] = useQueryParams({
    edit: BooleanParam,
    openChecklist: StringParam,
    openChecklistTemplate: StringParam,
  });

  const {
    close,
    open,
    isOpen,
    readOnly,
    isAdd,
    isEdit,
    isCopy,
    setData,
    setLoading,
    isFulfillment,
    loading,
    data,
    modalMode,
  } = useContext(ChecklistModalContext);
  const { t } = useTranslation(['common', 'checklist']);

  const {
    data: checklistTemplateData,
    loading: onGetChecklistTemplateLoading,
  } = useChecklistTemplateQuery({
    variables: {
      id: query.openChecklistTemplate || '',
    },
    context: { _instance: 'node' },
    skip: !query.openChecklistTemplate,
  });

  const closeConfirm = () => {
    GoogleAnalyticsClient.event(GA_EVENTS.closeChecklistModal);
    close({ refresh: 'false' });
  };

  useEffect(() => {
    setLoading(() => onGetChecklistTemplateLoading);
    if (checklistTemplateData?.checklistTemplate?.tasks) {
      setData({
        ...checklistTemplateData.checklistTemplate,
        tasks: checklistTemplateData?.checklistTemplate?.tasks.sort((a, b) => {
          if (Number(a.ordering_value) > Number(b.ordering_value)) {
            return 1;
          }
          if (Number(a.ordering_value) < Number(b.ordering_value)) return -1;
          return 0;
        }),
      });
    }
  }, [
    onGetChecklistTemplateLoading,
    checklistTemplateData,
    setData,
    setLoading,
  ]);

  const handleClick = () => {
    open(true);
    setQuery({ edit: true });
  };

  function modalTitle() {
    if (isAdd) {
      return t('checklist:Add Checklist');
    }
    if (isEdit) {
      return t('checklist:Edit Checklist');
    }
    if (isCopy) {
      return t('checklist:Copy Checklist');
    }
    if (readOnly || isFulfillment) {
      return data ? data.name : '';
    }
    return 'ChecklistNameProbably';
  }

  if (loading || onGetChecklistTemplateLoading) {
    return (
      <Drawer title={modalName} width={modalWidth} open={isOpen}>
        <Loading loading />
      </Drawer>
    );
  }

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        close({});
      }}
      width={modalWidth}
      title={modalName}
      closeIcon={false}
      closable
      styles={{
        body: {
          padding: SPACING.NONE,
        },
        header: {
          display: 'none',
        },
      }}
    >
      <ModalTop modifiers={modifiers}>
        <ModalElements.Title data-testid="checklist-modal">
          {modalTitle()}
        </ModalElements.Title>
        <Icons modifiers={[]}>
          {readOnly && !modalMode && (
            <Icons.Edit
              data-testid="toggleEdit"
              title={t('common:Edit')}
              onClick={handleClick}
            />
          )}
          <Icons.Exit data-testid="closeModalIcon" onClick={closeConfirm} />
        </Icons>
      </ModalTop>
      {children}
    </Drawer>
  );
}
