import React, { ChangeEvent, useCallback, useContext } from 'react';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { getEnvironment } from '@optii/shared/utils/config';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { ChecklistItemContext } from '../../contexts';
import elements, {
  FlexContainer,
  FlexItem,
  RequiredFlag,
} from '../../elements';
import TaskNameField from '../TaskNameField';
import SelectType from '../SelectType';
import { CHECKLIST_TASK_FIELDS } from '../../taskConstants';

function CheckboxType() {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    isFulfillment,
    formValues,
    onFormChange,
    isReadOnly,
  } = useContext(ChecklistItemContext);

  const { CheckBox } = elements;

  const readOnly = !isFulfillment ? isReadOnly : isFulfillment;

  const isRequired =
    String(
      formValues[CHECKLIST_TASK_FIELDS.required as keyof typeof formValues],
    ) === 'true';
  const checked = isFulfillment
    ? !!fulfillmentValues[
        CHECKLIST_TASK_FIELDS.dataCheckboxChecked as keyof typeof fulfillmentValues
      ]
    : !!formValues?.defaultValue?.value;

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked: eventChecked } = event.target;
      event.stopPropagation();
      if (eventChecked)
        GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskSelected);
      else GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskDeselected);

      if (isFulfillment) {
        onFulfillmentChange({
          ...formValues,
          [CHECKLIST_TASK_FIELDS.dataCheckboxChecked]: eventChecked,
        });

        console.log(formValues);
      } else {
        onFormChange({
          name: 'defaultValue',
          value: {
            value: eventChecked,
          },
        });
      }
    },
    [formValues, onFulfillmentChange, onFormChange, isFulfillment],
  );

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container">
      <FlexContainer col>
        <FlexContainer>
          <FlexItem flex="0" row>
            {isRequired && <RequiredFlag />}
            <CheckBox
              checked={checked}
              tabIndex={-1}
              onChange={onChange}
              readOnly={readOnly}
              data-testid="checklist-task-checkbox-check"
            />
          </FlexItem>
          <FlexItem>
            <TaskNameField />
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default CheckboxType;
